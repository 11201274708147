import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Icon, Menu, MenuButton, useCustomTriggerMenu, type MenuItem } from ".";
import { ConfirmationModalActionAtom } from "../atoms";
import { useConfirmationModalAction, useOpenConfirmationModal } from "../hooks";
import { GridCardType, IconType } from "../types";
import { classes } from "../utils";

import styles from "../styles/components/TemplateGridCard.module.scss";

export default ({
  author,
  colorStyle,
  id,
  remove,
  source,
  tags,
  title,
}: GridCardType & {
  remove: (id: string) => void;
  source: "workouts" | "recipes";
}) => {
  const SOURCE_ID = `template-grid-card-${id}`;

  const [buttonHover, setButtonHover] = useState<boolean>(false);
  const { open, ref, toggleOpen } = useCustomTriggerMenu();
  const navigate = useNavigate();
  const openConfirmationModal = useOpenConfirmationModal();
  useConfirmationModalAction(SOURCE_ID, "confirmed", () => remove(id));

  const deleteTemplateConfirmationModal: ConfirmationModalActionAtom = useMemo(
    () => ({
      confirmButtonLabel: "Delete",
      description: `Are you sure you want to delete "${title}"?`,
      destructive: true,
    }),
    [title],
  );

  const menuItems: MenuItem[] = useMemo(
    () => [
      {
        icon: IconType.EDIT,
        label: "Edit",
        onClick: () => navigate(`/${source}/${id}/edit`),
      },
      {
        destructive: true,
        icon: IconType.DELETE,
        label: "Delete",
        onClick: () =>
          openConfirmationModal(deleteTemplateConfirmationModal, SOURCE_ID),
      },
    ],
    [
      SOURCE_ID,
      deleteTemplateConfirmationModal,
      id,
      navigate,
      openConfirmationModal,
      source,
    ],
  );

  return (
    <div
      {...{ ref }}
      {...classes(styles, [
        "component",
        colorStyle,
        buttonHover && "options-button-hover",
        open && "menu-open",
      ])}
      onClick={toggleOpen}
    >
      <div className={styles["top"]}>
        <div className={styles["title-and-author"]}>
          <div className={styles["title"]}>{title}</div>
          <div className={styles["author"]}>Created by {author}</div>
        </div>
        <Menu {...{ open }} items={menuItems}>
          <MenuButton
            className={styles["options-button"]}
            onMouseOut={() => setButtonHover(false)}
            onMouseOver={() => setButtonHover(true)}
          >
            <Icon size={20} type={IconType.MORE_VERT} />
          </MenuButton>
        </Menu>
      </div>
      <div className={styles["tags"]}>
        {tags.map((tag, index) => (
          <div className={styles["tag"]} key={index}>
            {tag}
          </div>
        ))}
      </div>
    </div>
  );
};
