import { useSetAtom } from "jotai";
import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useConfirmationModalAction, useOpenConfirmationModal } from ".";
import {
  createUpdateEventModalDataAtom,
  currentOpenModalAtom,
  startEventSourceAtom,
} from "../atoms";
import { ModalType } from "../components";
import { Event_t, EventType, NewEvent_t } from "../types";
import { noop } from "../utils";

interface Callbacks {
  create?: (newEvent: NewEvent_t) => void;
  delete?: () => void;
  update?: (updatedEvent: Event_t) => void;
}

export default (eventId?: number, callbacks?: Callbacks) => {
  const SOURCE_ID =
    eventId === undefined
      ? "event-menu-action"
      : `event-menu-action-${eventId}`;

  const setCurrentOpenModal = useSetAtom(currentOpenModalAtom);
  const navigate = useNavigate();
  const openConfirmationModal = useOpenConfirmationModal();
  const setCreateUpdateEventModalData = useSetAtom(
    createUpdateEventModalDataAtom,
  );
  const setStartEventSource = useSetAtom(startEventSourceAtom);
  const location = useLocation();
  useConfirmationModalAction(SOURCE_ID, "confirmed", callbacks?.delete || noop);

  const createEvent = useCallback(
    (initialEventType?: EventType) => {
      setCreateUpdateEventModalData({
        createEventCallback: callbacks?.create,
        id: null,
        initialEventType,
      });
      setCurrentOpenModal(ModalType.CREATE_UPDATE_EVENT);
    },
    [callbacks?.create, setCreateUpdateEventModalData, setCurrentOpenModal],
  );

  const deleteEvent = useCallback(
    (name: string) =>
      openConfirmationModal(
        {
          confirmButtonLabel: "Delete event",
          description: `Are you sure you want to delete ${name}?`,
          destructive: true,
        },
        SOURCE_ID,
      ),
    [SOURCE_ID, openConfirmationModal],
  );

  const editEvent = useCallback(
    (id: number) => {
      setCurrentOpenModal(ModalType.CREATE_UPDATE_EVENT);
      setCreateUpdateEventModalData({
        updateEventCallback: callbacks?.update,
        id,
      });
    },
    [callbacks?.update, setCreateUpdateEventModalData, setCurrentOpenModal],
  );

  const startEvent = useCallback(
    (id: number, type: EventType) => {
      setCurrentOpenModal(undefined);

      let slug;
      if (type === EventType.RECIPE) {
        slug = "/recipes";
      } else if (type === EventType.WORKOUT) {
        slug = "/workouts";
      }

      if (!slug) {
        return;
      }

      setStartEventSource(location.pathname);
      navigate(`${slug}/${id}`);
    },
    [location.pathname, navigate, setCurrentOpenModal, setStartEventSource],
  );

  return { createEvent, deleteEvent, editEvent, startEvent };
};
