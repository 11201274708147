import { Icon, Menu, MenuButton, useMenuStore, type MenuItem } from ".";
import { IconType, SubscriptionStatus } from "../types";
import { classes } from "../utils";

import styles from "../styles/components/SubscriptionCard.module.scss";

interface Subscription {
  name: string;
  price: string;
  startDate: Date;
  status: SubscriptionStatus;
  trainer: string;
}

const subscriptionStatusToBlockStyleMap: {
  [key in SubscriptionStatus]: { string: string; className: string };
} = {
  [SubscriptionStatus.ACTIVE]: { string: "Active", className: "active" },
  [SubscriptionStatus.UPCOMING]: { string: "Upcoming", className: "upcoming" },
  [SubscriptionStatus.CANCELLED]: {
    string: "Cancelled",
    className: "cancelled",
  },
};

const menuItems: MenuItem[] = [
  {
    icon: IconType.CREDIT_CARD,
    label: "Update payment method",
    onClick: () => console.log("update payment method"),
  },
  {
    icon: IconType.TUNE,
    label: "Modify subscription",
    onClick: () => console.log("modify subscription"),
  },
];

export default ({ name, price, startDate, status, trainer }: Subscription) => {
  const menu = useMenuStore();

  const open = menu.useState("open");

  const { string, className } = subscriptionStatusToBlockStyleMap[status];

  const startDateString = startDate.toLocaleString("en-US", {
    timeZone: "UTC",
    month: "numeric",
    day: "numeric",
  });

  const statusString = `${string} ${
    status === SubscriptionStatus.UPCOMING ? `- Starts ${startDateString}` : ""
  }`;

  return (
    <div className={styles["component"]}>
      <div className={styles["top"]}>
        <div className={styles["name"]}>{name}</div>
        <Menu items={menuItems} store={menu}>
          <MenuButton {...classes(styles, ["menu-button", open && "open"])}>
            <Icon size={20} type={IconType.MORE_VERT} />
          </MenuButton>
        </Menu>
      </div>
      <div {...classes(styles, ["status", className])}>{statusString}</div>
      <div className={styles["details"]}>
        <div className={styles["group"]}>
          <div>Trainer/Gym</div>
          {trainer}
        </div>
        <div className={styles["group"]}>
          <div>Price</div>
          {price}
        </div>
      </div>
    </div>
  );
};
