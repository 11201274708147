import {
  Select,
  SelectItem,
  SelectPopover,
  SelectProvider,
  useSelectStore,
} from "@ariakit/react";
import {
  createElement,
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useCallback,
  useState,
} from "react";

import { Icon } from "..";
import { IconType } from "../../types";
import { classes } from "../../utils";

import styles from "../../styles/components/Select/index.module.scss";

export interface SelectHeadType {
  error?: boolean;
  isHeadFocused: boolean;
  open: boolean;
  type: "primary" | "outline" | "outline-light";
  value: string;
}

export interface SelectItem {
  icon?: IconType;
  label: string;
  onClick?: () => void;
  value: string;
}

export type SelectSize = "large" | "small";

export type SelectType = "primary" | "outline" | "outline-light";

const iconSizes = {
  large: 18,
  small: 14,
};

export default ({
  error,
  head,
  items,
  onOpen,
  sameWidth,
  setValue,
  size,
  type,
  value,
}: {
  error?: boolean;
  head: FunctionComponent<SelectHeadType>;
  items: SelectItem[];
  onOpen?: () => void;
  sameWidth?: boolean;
  setValue: Dispatch<SetStateAction<string>>;
  size: SelectSize;
  type: SelectType;
  value: string;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isHeadFocused, setHeadFocused] = useState<boolean>(false);

  const customSetOpen = useCallback(
    (newValue: boolean) => {
      if (newValue) {
        onOpen?.();
      }
      setOpen(newValue);
    },
    [onOpen],
  );

  const menu = useSelectStore({ open, setOpen: customSetOpen });

  return (
    <SelectProvider {...{ setValue, value }}>
      <Select
        className={styles["head"]}
        onBlur={() => setHeadFocused(false)}
        onFocus={() => setHeadFocused(true)}
        store={menu}
      >
        {createElement(head, { error, isHeadFocused, open, type, value })}
      </Select>
      <SelectPopover
        {...{ sameWidth }}
        {...classes(styles, ["menu", type])}
        gutter={4}
        store={menu}
      >
        {items.map(({ icon, label, onClick, value }) => (
          <SelectItem
            {...{ value }}
            {...classes(styles, ["item", size])}
            focusOnHover
            key={value}
            onClick={() => (onClick ? onClick() : setValue(value))}
          >
            {icon && <Icon filled size={iconSizes[size]} type={icon} />}
            <span>{label}</span>
          </SelectItem>
        ))}
      </SelectPopover>
    </SelectProvider>
  );
};
