import { Session } from "@supabase/supabase-js";

export enum UserType {
  USER = "user",
  TRAINER = "trainer",
}

export enum EventType {
  WORKOUT = "WORKOUT",
  RECIPE = "RECIPE",
}

export interface FeaturedCardType {
  author?: string;
  colorStyle: "sky" | "rose";
  id: FormattedId;
  tags: string[];
  title: string;
}

export interface GridCardType {
  author: string;
  colorStyle: "lime" | "sky" | "teal" | "yellow";
  id: string;
  tags: string[];
  title: string;
}

export enum InvoiceStatus {
  OVERDUE = "OVERDUE",
  PAID = "PAID",
  UNPAID = "UNPAID",
  UPCOMING = "UPCOMING",
}

export enum SubscriptionStatus {
  ACTIVE = "ACTIVE",
  UPCOMING = "UPCOMING",
  CANCELLED = "CANCELLED",
}

export enum EventStatus {
  COMPLETED = "COMPLETED",
  FUTURE = "FUTURE",
  IN_PROGRESS = "IN_PROGRESS",
  INCOMPLETE = "INCOMPLETE",
  SKIPPED = "SKIPPED",
}

export enum ExerciseStatus {
  COMPLETED = "COMPLETED",
  FUTURE = "FUTURE",
  IN_PROGRESS = "IN_PROGRESS",
  INCOMPLETE = "INCOMPLETE",
  SKIPPED = "SKIPPED",
}

export enum WorkoutStepType {
  EXERCISE = "EXERCISE",
  BREAK = "BREAK",
}

export interface EventPreview {
  author?: string;
  eventType: EventType;
  id: FormattedId;
  tags: string[];
  title: string;
}

export interface FormattedId {
  rowId: number;
  dbTable: DbTable;
}

export enum DbTable {
  EVENTS = "Events",
  ICONS = "Icons",
  INVOICES = "Invoices",
  RECIPES = "Recipes",
  SUBSCRIPTIONS = "Subscriptions",
  USERS = "Users",
  WORKOUTS = "Workouts",
}

export enum CommunicationMethodType {
  EMAIL = "EMAIL",
  PHONE_NUMBER = "PHONE_NUMBER",
}

export interface CommunicationMethod {
  type: CommunicationMethodType;
  value: string;
}

export interface FilterableCardFilterTag {
  id: string;
  label: string;
}

export enum Availability {
  IN_PERSON = "IN_PERSON",
  ONLINE = "ONLINE",
  BOTH = "BOTH",
}

export enum InPersonAvailability {
  INCALL = "INCALL",
  OUTCALL = "OUTCALL",
  BOTH = "BOTH",
}

export enum IconType {
  ACCOUNT_CIRCLE = "ACCOUNT_CIRCLE",
  ADD = "ADD",
  ARROW_DOWN = "ARROW_DOWN",
  ARROW_LEFT = "ARROW_LEFT",
  ARROW_RIGHT = "ARROW_RIGHT",
  ARROW_UP = "ARROW_UP",
  CALENDAR = "CALENDAR",
  CALL = "CALL",
  CHECK = "CHECK",
  CHEVRON_DOWN = "CHEVRON_DOWN",
  CHEVRON_LEFT = "CHEVRON_LEFT",
  CHEVRON_RIGHT = "CHEVRON_RIGHT",
  CHEVRON_UP = "CHEVRON_UP",
  CLOSE = "CLOSE",
  CONTENT_COPY = "CONTENT_COPY",
  CREDIT_CARD = "CREDIT_CARD",
  DELETE = "DELETE",
  DOWNLOAD = "DOWNLOAD",
  EDIT = "EDIT",
  EXERCISE = "EXERCISE",
  EXIT_TO_APP = "EXIT_TO_APP",
  HOME = "HOME",
  INCOMPLETE_CIRCLE = "INCOMPLETE_CIRCLE",
  LANGUAGE = "LANGUAGE",
  LOCAL_FIRE_DEPARTMENT = "LOCAL_FIRE_DEPARTMENT",
  LOGOUT = "LOGOUT",
  MAIL = "MAIL",
  MENU = "MENU",
  MORE_VERT = "MORE_VERT",
  PAUSE = "PAUSE",
  PLAY_ARROW = "PLAY_ARROW",
  REMOVE = "REMOVE",
  RESTAURANT = "RESTAURANT",
  SAVE = "SAVE",
  SCHEDULE = "SCHEDULE",
  SEARCH = "SEARCH",
  SKIP = "SKIP",
  STAR = "STAR",
  STAR_HALF_FILLED = "STAR_HALF_FILLED",
  TUNE = "TUNE",
  UNFOLD_LESS = "UNFOLD_LESS",
  UNFOLD_MORE = "UNFOLD_MORE",
  VISIBILITY = "VISIBILITY",
  WARNING = "WARNING",
}

export interface SvgAssetProps {
  size: number;
}

export interface IconProps extends SvgAssetProps {
  filled?: boolean;
  type: IconType;
}

export interface RecentWorkout {
  date: Date;
  id: FormattedId;
  name: string;
  status: EventStatus;
  title: string;
  workoutId: FormattedId;
}

export interface AuthSession {
  session: Session | null;
  status: string;
}

export enum Breakpoint {
  MOBILE = "MOBILE",
  TABLET = "TABLET",
  SMALL_DESKTOP = "SMALL_DESKTOP",
  DESKTOP = "DESKTOP",
}

export interface NewEvent_t {
  date: Date;
  durationMinutes: number;
  name: string;
  selectedTraineeIds: number[];
  type: EventType;
}

export interface Event_t extends NewEvent_t {
  id: number;
}

export type InputType = "date" | "text" | "time" | "password";
