import { ReactNode } from "react";
import { Link } from "react-router-dom";

import styles from "../styles/components/AuthPageWrapper.module.scss";

export default ({ children }: { children: ReactNode }) => {
  return (
    <div className={styles["component"]}>
      <div className={styles["left-container"]}>
        <div className={styles["left"]}>
          <Link to="/">
            <img className={styles["logo"]} />
          </Link>
          {children}
        </div>
      </div>
      <div className={styles["right"]}>
        <img />
      </div>
    </div>
  );
};
