import toast, { ToastBar, Toaster } from "react-hot-toast";

import { Icon } from ".";
import { IconType } from "../types";
import { classes } from "../utils";

import styles from "../styles/components/Toaster.module.scss";

type ToastType = "error" | "success";

const toastTypeToIconMap: Record<ToastType, IconType> = {
  error: IconType.CLOSE,
  success: IconType.CHECK,
};

export const createToast = (label: string, type: ToastType = "success") => {
  toast(<Toast {...{ label, type }} />, {
    duration: 3000,
    position: "bottom-right",
  });
};

const Toast = ({ label, type }: { label: string; type: ToastType }) => {
  return (
    <>
      <div {...classes(styles, ["icon-container", type])}>
        <Icon size={18} type={toastTypeToIconMap[type]} />
      </div>
      {label}
    </>
  );
};

export default () => {
  return (
    <Toaster containerClassName={styles["container"]}>
      {(toast) => <ToastBar {...{ toast }} style={{ ...toast.style }} />}
    </Toaster>
  );
};
