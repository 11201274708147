import { useCallback, useMemo, useState } from "react";

import Modal, { InputRow, ModalType } from ".";
import { createToast } from "..";
import { ConfirmationModalActionAtom } from "../../atoms";
import {
  useConfirmationModalAction,
  useConnectModalResetCallback,
  useOpenConfirmationModal,
} from "../../hooks";
import { getInvalidFields } from "../../utils";

import styles from "../../styles/components/Modals/UpdateEmailAddressModal.module.scss";

const SOURCE_ID = ModalType.UPDATE_EMAIL_ADDRESS;

const updateEmailAddress = () => {
  console.log("update email address");
};

const updateEmailAddressConfirmationModal: ConfirmationModalActionAtom = {
  confirmButtonLabel: "Change",
  description: "Are you sure you want to change your email address?",
};

const fieldKeys = ["email-address", "confirmed-email-address"] as const;
type Field = (typeof fieldKeys)[number];

const defaults = {
  "email-address": "",
  "confirmed-email-address": "",
};

export default () => {
  const [emailAddress, setEmailAddress] = useState<string>(
    defaults["email-address"],
  );
  const [confirmedEmailAddress, setConfirmedEmailAddress] = useState<string>(
    defaults["confirmed-email-address"],
  );
  const [invalidFields, setInvalidFields] = useState<Field[]>([]);
  const openConfirmationModal = useOpenConfirmationModal();
  useConfirmationModalAction(SOURCE_ID, "confirmed", updateEmailAddress);

  const formFieldData = useMemo(
    () => ({
      "email-address": { setValue: setEmailAddress, value: emailAddress },
      "confirmed-email-address": {
        setValue: setConfirmedEmailAddress,
        value: confirmedEmailAddress,
      },
    }),
    [confirmedEmailAddress, emailAddress],
  );

  useConnectModalResetCallback(SOURCE_ID, () => {
    fieldKeys.forEach((key) => formFieldData[key].setValue(defaults[key]));
    setInvalidFields([]);
  });

  const handleSubmit = useCallback(() => {
    const invalidFieldsMap: Record<Field, boolean> = {
      "email-address": !emailAddress,
      "confirmed-email-address":
        !confirmedEmailAddress || emailAddress !== confirmedEmailAddress,
    };
    const updatedInvalidFields = getInvalidFields<Field>(invalidFieldsMap);
    setInvalidFields(updatedInvalidFields);

    if (confirmedEmailAddress && emailAddress !== confirmedEmailAddress) {
      createToast("Error, emails do not match.", "error");
    } else if (updatedInvalidFields.length) {
      createToast("Error, please fill out all required fields.", "error");
    } else {
      openConfirmationModal(updateEmailAddressConfirmationModal, SOURCE_ID);
    }
  }, [confirmedEmailAddress, emailAddress, openConfirmationModal]);

  return (
    <Modal
      footerRightButtonAction={handleSubmit}
      footerRightButtonLabel="Update"
      title="Update email address"
      type={SOURCE_ID}
    >
      <div className={styles["rows"]}>
        <InputRow
          {...{ invalidFields, setInvalidFields }}
          fieldType="email-address"
          label="New email address"
          placeholder="Email address"
          setValue={setEmailAddress}
          type="vertical"
          value={emailAddress}
        />
        <InputRow
          {...{ invalidFields, setInvalidFields }}
          fieldType="confirmed-email-address"
          label="Confirm new email address"
          placeholder="Email address"
          setValue={setConfirmedEmailAddress}
          type="vertical"
          value={confirmedEmailAddress}
        />
      </div>
    </Modal>
  );
};
