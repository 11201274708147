import { Icon, type SelectHeadType, type SelectItem } from "..";
import { IconType } from "../../types";
import { classes } from "../../utils";

import styles from "../../styles/components/Select/SelectHead.module.scss";

type SelectHeadWithItems = SelectHeadType & {
  items: SelectItem[];
  width?: string;
};

const getSelectedItem = (items: SelectItem[], value: string) =>
  items.find((item) => item.value === value);

export default ({
  error,
  isHeadFocused,
  items,
  open,
  type,
  value,
  width,
}: SelectHeadWithItems) => {
  const selectedItem = getSelectedItem(items, value);

  return (
    <div
      {...classes(styles, [
        "component",
        error && "error",
        isHeadFocused && "focused",
        type,
        open && "open",
      ])}
      style={{ width }}
    >
      <div className={styles["option"]}>
        {selectedItem?.icon && (
          <Icon filled size={18} type={selectedItem.icon} />
        )}
        <div className={styles["label"]}>{selectedItem?.label}</div>
      </div>
      <div className={styles["arrow"]}>
        <Icon
          size={18}
          type={open ? IconType.CHEVRON_UP : IconType.CHEVRON_DOWN}
        />
      </div>
    </div>
  );
};

export const SelectHead_Profile_EditingSocialMediaLink = ({
  items,
  open,
  value,
}: SelectHeadWithItems) => {
  const selectedItem = getSelectedItem(items, value);

  return (
    <div
      {...classes(styles, [
        "component",
        "profile-editing-social-media-link",
        open && "open",
      ])}
    >
      <div className={styles["option"]}>
        {selectedItem?.icon && (
          <Icon filled size={16} type={selectedItem.icon} />
        )}
      </div>
      <div className={styles["arrow"]}>
        <Icon
          size={18}
          type={open ? IconType.CHEVRON_UP : IconType.CHEVRON_DOWN}
        />
      </div>
    </div>
  );
};
