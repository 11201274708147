import {
  ChangeEvent,
  Dispatch,
  FocusEvent,
  KeyboardEvent,
  SetStateAction,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import { Icon } from ".";
import { IconType, InputType } from "../types";
import { classes } from "../utils";

import styles from "../styles/components/Input.module.scss";

export default (
  props: {
    autofit?: boolean;
    icon?: IconType;
    error?: boolean;
    numeric?: boolean;
    numericPositive?: boolean;
    onFocus?: (e: FocusEvent) => void;
    placeholder?: string;
    setValue: Dispatch<SetStateAction<string>>;
    size?: "small" | "medium";
    value: string;
  } & ({ rows: number } | { inputType?: InputType }),
) => {
  const {
    autofit,
    icon,
    error,
    numeric,
    numericPositive,
    onFocus,
    placeholder,
    setValue,
    size = "medium",
    value,
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const measurementRef = useRef<HTMLSpanElement>(null);
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [lineHeight, setLineHeight] = useState<number>(0);

  const onBlur = useCallback(() => {
    if (numericPositive) {
      setValue((val) => Math.max(0, Number(val)).toString());
    }
  }, [numericPositive, setValue]);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
      if (numeric) {
        const isNegative = e.target.value[0] === "-";
        setValue(
          `${isNegative ? "-" : ""}${e.target.value.replace(/\D/g, "")}`,
        );
      } else {
        setValue(e.target.value);
      }
    },
    [numeric, setValue],
  );

  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      const isArrowKey = ["ArrowUp", "ArrowDown"].includes(e.key);

      if (!isArrowKey) {
        return;
      }

      setValue((strValue: string) => {
        let delta = 0;

        if (e.key === "ArrowUp") {
          e.preventDefault();
          delta += 1;
        } else if (e.key === "ArrowDown") {
          e.preventDefault();
          delta -= 1;
        }

        return (Number(strValue) + delta).toString();
      });
    },
    [setValue],
  );

  // autofit
  useLayoutEffect(() => {
    const measurementSpan = measurementRef.current;
    const inputElement = inputRef.current || textareaRef.current;

    if (!measurementSpan || !inputElement) {
      return;
    }

    const computedStyles = window.getComputedStyle(inputElement);
    const paddingTop = parseFloat(
      computedStyles.getPropertyValue("padding-top"),
    );
    const paddingBottom = parseFloat(
      computedStyles.getPropertyValue("padding-bottom"),
    );
    const borderWidth = parseFloat(
      computedStyles.getPropertyValue("border-width"),
    );
    const lh = parseFloat(computedStyles.getPropertyValue("line-height"));

    setWidth(measurementSpan.offsetWidth);
    setHeight(
      measurementSpan.offsetHeight +
        (value.endsWith("\n") ? lh : 0) +
        paddingTop +
        paddingBottom +
        borderWidth * 2,
    );
    setLineHeight(lh);
  }, [value]);

  if ("rows" in props) {
    const { rows } = props;

    return (
      <>
        <textarea
          {...classes(styles, ["component-multiline", error && "error", size])}
          {...{ onChange, onFocus, placeholder, value }}
          ref={textareaRef}
          rows={autofit ? undefined : rows}
          style={autofit ? { height, minHeight: rows * lineHeight } : {}}
        />
        <span ref={measurementRef}>{value}</span>
      </>
    );
  }

  return (
    <div
      {...classes(styles, ["component-single-line", error && "error", size])}
    >
      {icon && (
        <div className={styles["icon-container"]}>
          <Icon size={16} type={icon} />
        </div>
      )}
      <span ref={measurementRef}>{value || placeholder}</span>
      <input
        {...{ onBlur, onChange, onFocus, onKeyDown, placeholder, value }}
        ref={inputRef}
        style={autofit ? { width } : {}}
        type={props.inputType || "text"}
      />
    </div>
  );
};
