import { useAtom, useSetAtom } from "jotai";
import { useCallback } from "react";
import { createPortal } from "react-dom";

import { Button, Icon } from ".";
import {
  confirmationModalActionAtom,
  confirmationModalStatusAtom,
} from "../atoms";
import { IconType } from "../types";

import styles from "../styles/components/ConfirmationModal.module.scss";

export default () => {
  const [confirmationModal, setConfirmationModal] = useAtom(
    confirmationModalActionAtom,
  );
  const setConfirmationModalStatus = useSetAtom(confirmationModalStatusAtom);

  const onCancel = useCallback(() => {
    if (confirmationModal?.preventCancel) {
      return;
    }

    setConfirmationModalStatus("canceled");
    setConfirmationModal(undefined);
  }, [
    confirmationModal?.preventCancel,
    setConfirmationModal,
    setConfirmationModalStatus,
  ]);

  const onConfirm = useCallback(() => {
    setConfirmationModalStatus("confirmed");
    setConfirmationModal(undefined);
  }, [setConfirmationModal, setConfirmationModalStatus]);

  if (!confirmationModal) {
    return null;
  }

  const { confirmButtonLabel, description, destructive, preventCancel, icon } =
    confirmationModal;

  return createPortal(
    <div className={styles["modal-background"]} onClick={onCancel}>
      <div
        className={styles["modal-container"]}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles["icon-and-description"]}>
          <Icon size={40} type={icon || IconType.WARNING} />
          <div className={styles["description"]}>{description}</div>
        </div>
        <div className={styles["buttons"]}>
          {!preventCancel && (
            <Button
              action={onCancel}
              label="Cancel"
              size="medium"
              type="outline"
            />
          )}
          <Button
            {...{ destructive }}
            action={onConfirm}
            label={confirmButtonLabel}
            size="medium"
            type="primary"
          />
        </div>
      </div>
    </div>,
    document.body,
  );
};
