import { useAtom } from "jotai";
import { useEffect } from "react";

import { useCloseModal } from ".";
import {
  confirmationModalSourceAtom,
  ConfirmationModalStatus,
  confirmationModalStatusAtom,
} from "../atoms";

export default (
  source: string,
  actionStatus: ConfirmationModalStatus,
  actionCallback: () => void,
) => {
  const [confirmationModalStatus, setConfirmationModalStatus] = useAtom(
    confirmationModalStatusAtom,
  );
  const closeModal = useCloseModal();
  const [confirmationModalSource, setConfirmationModalSource] = useAtom(
    confirmationModalSourceAtom,
  );

  useEffect(() => {
    if (source !== confirmationModalSource) {
      return;
    }

    if (confirmationModalStatus === actionStatus) {
      closeModal();
      actionCallback();
    }

    if (
      confirmationModalStatus !== "open" &&
      confirmationModalStatus !== undefined
    ) {
      setConfirmationModalStatus(undefined);
      setConfirmationModalSource(undefined);
    }
    // excluding all the setters because it causes extra recalculations that aren't necessary
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionStatus, confirmationModalSource, confirmationModalStatus, source]);
};
