import { Button } from ".";
import { IconType } from "../types";

import styles from "../styles/components/EditableImage.module.scss";

export default ({ clear, image }: { clear: () => void; image: string }) => {
  return (
    <div
      className={styles["component"]}
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className={styles["edit-button"]}>
        <Button
          action={clear}
          icon={{ filled: true, type: IconType.EDIT }}
          label="Edit"
          size="extra-small"
          type="primary"
        />
      </div>
    </div>
  );
};
