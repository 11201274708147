import { useMemo } from "react";
import { Link } from "react-router-dom";

import { Button, EventStatusIndicator, ScrollingWrapper } from ".";
import { RecentWorkout } from "../types";
import { classes, getPathFromId, noop } from "../utils";

import styles from "../styles/components/RecentWorkoutsList.module.scss";

const RecentWorkoutCard = ({
  date,
  id,
  name,
  status,
  title,
  workoutId,
}: RecentWorkout) => {
  const eventDateTime = useMemo(() => {
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "numeric",
      day: "2-digit",
      year: "numeric",
    });
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
    });

    return `${formattedDate}, ${formattedTime
      .toLowerCase()
      .replaceAll(" ", "")}`;
  }, [date]);

  return (
    <div className={styles["card"]}>
      <div className={styles["info"]}>
        <div className={styles["row"]}>
          <div className={styles["title"]}>{title}</div>
          <div className={styles["status"]}>
            <EventStatusIndicator {...{ status }} size="small" />
          </div>
        </div>
        <div className={styles["subtext"]}>
          <div>{name}</div>
          <div>{eventDateTime}</div>
        </div>
      </div>
      <Link to={`${getPathFromId(workoutId)}/review?eventId=${id.rowId}`}>
        <Button action={noop} label="View" size="medium" type="primary" />
      </Link>
    </div>
  );
};

export default ({
  recentWorkouts,
  title,
}: {
  recentWorkouts: RecentWorkout[];
  title: string;
}) => {
  return (
    <div {...classes(styles, ["component", !recentWorkouts.length && "empty"])}>
      <div className={styles["component-title"]}>{title}</div>
      {recentWorkouts.length ? (
        <ScrollingWrapper>
          {recentWorkouts.map((recentWorkout, index) => (
            <RecentWorkoutCard {...recentWorkout} key={index} />
          ))}
        </ScrollingWrapper>
      ) : (
        <div className={styles["empty-section"]}>No recent workouts</div>
      )}
    </div>
  );
};
