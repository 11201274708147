import { useCallback, useMemo, useRef, useState } from "react";

import {
  Button,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  useCustomTriggerMenu,
} from ".";
import { initEvents } from "../constants";
import { useEventMenuActions } from "../hooks";
import { Event_t, EventType, IconType, NewEvent_t } from "../types";
import { classes, createEvent, deleteEvent, today } from "../utils";

import styles from "../styles/components/ItinerarySidebar.module.scss";

interface DateGroup {
  dayAndMonth: string;
  events: Event_t[];
  weekday: string;
}

const eventTypeToStyleMap: Record<
  EventType,
  { icon: IconType; className: string }
> = {
  [EventType.RECIPE]: {
    icon: IconType.RESTAURANT,
    className: "recipe",
  },
  [EventType.WORKOUT]: {
    icon: IconType.EXERCISE,
    className: "workout",
  },
};

const Event = ({
  durationMinutes,
  id,
  name,
  onDelete,
  type,
}: Event_t & { onDelete: () => void }) => {
  const { open, ref, toggleOpen } = useCustomTriggerMenu();
  const { deleteEvent, editEvent, startEvent } = useEventMenuActions(id, {
    delete: onDelete,
  });

  const menuItems: MenuItem[] = useMemo(
    () => [
      {
        icon: IconType.PLAY_ARROW,
        label: "Start",
        onClick: () => startEvent(id, type),
      },
      { icon: IconType.EDIT, label: "Edit", onClick: () => editEvent(id) },
      {
        destructive: true,
        icon: IconType.DELETE,
        label: "Delete",
        onClick: () => deleteEvent(name),
      },
    ],
    [deleteEvent, editEvent, id, name, startEvent, type],
  );

  return (
    <div
      {...{ ref }}
      {...classes(styles, [
        "event",
        eventTypeToStyleMap[type].className,
        open && "menu-open",
      ])}
      onClick={toggleOpen}
    >
      <Icon size={20} type={eventTypeToStyleMap[type].icon} />
      <div className={styles["name"]}>{name}</div>
      <div className={styles["duration"]}>{durationMinutes} min</div>
      <Menu {...{ open }} items={menuItems}>
        <MenuButton className={styles["options-button"]}>
          <Icon size={16} type={IconType.MORE_VERT} />
        </MenuButton>
      </Menu>
    </div>
  );
};

const DateGroup = ({
  dayAndMonth,
  events,
  onDeleteEvent,
  weekday,
}: DateGroup & { onDeleteEvent: (id: number) => void }) => {
  return (
    <div className={styles["group"]}>
      <div className={styles["date"]}>
        <div>{dayAndMonth}</div>
        <div>{weekday}</div>
      </div>
      {events.length ? (
        <div className={styles["events"]}>
          {events.map((event) => (
            <Event
              {...event}
              key={event.id}
              onDelete={() => onDeleteEvent(event.id)}
            />
          ))}
        </div>
      ) : (
        <div className={styles["empty-date"]}>Nothing scheduled for today</div>
      )}
    </div>
  );
};

const createEmptyDateGroup = (date: Date): DateGroup => {
  const dayAndMonth = date.toLocaleString("en-US", {
    timeZone: "UTC",
    month: "long",
    day: "numeric",
  });
  const weekday = date.toLocaleString("en-US", {
    timeZone: "UTC",
    weekday: "long",
  });

  return { dayAndMonth, events: [], weekday };
};

const groupEventsByDate = (events: Event_t[], filter?: EventType) => {
  const nextYear = new Date(today.getTime());
  nextYear.setFullYear(nextYear.getFullYear() + 1);

  const futureEventsWithinYear = events.filter(
    ({ date, type }) =>
      (!filter || type === filter) && date > new Date() && date < nextYear,
  );

  const dateGroups: DateGroup[] = [createEmptyDateGroup(today)];
  futureEventsWithinYear.forEach((event) => {
    const dateGroup = createEmptyDateGroup(event.date);
    const dateIndex = dateGroups.findIndex(
      ({ dayAndMonth }) => dayAndMonth === dateGroup.dayAndMonth,
    );

    if (dateIndex === -1) {
      dateGroups.push({ ...dateGroup, events: [event] });
    } else {
      dateGroups[dateIndex].events.push(event);
    }
  });

  return dateGroups.length === 1 && !dateGroups[0].events.length
    ? []
    : dateGroups;
};

const CreateEventButton = ({
  action,
}: {
  action: (newEvent: NewEvent_t) => void;
}) => {
  const { createEvent } = useEventMenuActions(undefined, { create: action });

  return (
    <Button
      action={createEvent}
      icon={{ type: IconType.ADD }}
      label="Create Event"
      size="medium"
      type="primary"
    />
  );
};

export default ({ filter }: { filter?: EventType }) => {
  const [events, setEvents] = useState<Event_t[]>(initEvents);
  const listRef = useRef<HTMLDivElement>(null);

  const scrollToToday = useCallback(() => {
    if (listRef.current) {
      listRef.current.scrollTop = 0;
    }
  }, []);

  const eventGroups = useMemo(
    () => groupEventsByDate(events, filter),
    [events, filter],
  );

  return (
    <div className={styles["component"]}>
      <div className={styles["top"]}>
        <div className={styles["title"]}>Calendar</div>
        <div className={styles["buttons"]}>
          <Button
            action={scrollToToday}
            label="Today"
            size="medium"
            type="primary"
          />
          <CreateEventButton
            action={(newEvent) => createEvent(setEvents, newEvent)}
          />
        </div>
      </div>
      {eventGroups.length ? (
        <div className={styles["list"]} ref={listRef}>
          {eventGroups.map((dateGroup, index) => (
            <DateGroup
              {...dateGroup}
              key={index}
              onDeleteEvent={(id) => deleteEvent(setEvents, id)}
            />
          ))}
        </div>
      ) : (
        <div className={styles["empty"]}>No events scheduled</div>
      )}
    </div>
  );
};
