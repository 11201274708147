import { Dispatch, SetStateAction, useCallback, useMemo } from "react";

import { Combobox, Icon, type ComboboxItem } from ".";
import { IconType } from "../types";
import { classes, exclude } from "../utils";

import styles from "../styles/components/ShareListInput.module.scss";

const SelectedItem = ({
  image,
  name,
  remove,
}: ComboboxItem & {
  remove: () => void;
}) => {
  return (
    <div className={styles["item"]}>
      <div className={styles["info"]}>
        <img className={styles["profile"]} src={image} />
        <div className={styles["name"]}>{name}</div>
      </div>
      <div className={styles["close-button"]} onClick={remove}>
        <Icon size={16} type={IconType.CLOSE} />
      </div>
    </div>
  );
};

export default ({
  allItems,
  selectedIds,
  setSelectedIds,
  theme = "dark",
}: {
  allItems: ComboboxItem[];
  selectedIds: string[];
  setSelectedIds: Dispatch<SetStateAction<string[]>>;
  theme?: "light" | "dark";
}) => {
  const remove = useCallback(
    (id: string) => setSelectedIds((ids) => exclude(ids, id)),
    [setSelectedIds],
  );

  const unselectedItems = useMemo(
    () => allItems.filter(({ id }) => !selectedIds.includes(id)),
    [allItems, selectedIds],
  );

  const selectedItems = useMemo(
    () =>
      selectedIds
        .map((id) => allItems.find((item) => item.id === id))
        .filter((item): item is ComboboxItem => !!item),
    [allItems, selectedIds],
  );

  return (
    <div {...classes(styles, ["component", theme])}>
      {selectedItems.map((item) => (
        <SelectedItem {...item} key={item.id} remove={() => remove(item.id)} />
      ))}
      <Combobox
        items={unselectedItems}
        placeholder="Type to add trainee"
        setSelectedItems={setSelectedIds}
      />
    </div>
  );
};
