import { Icon } from ".";
import { type IconProps } from "../types";
import { classes } from "../utils";

import styles from "../styles/components/Button.module.scss";

type Size = "extra-small" | "small" | "medium" | "large";
type Type = "inverted" | "outline" | "primary" | "prominent" | "success";

const iconSizeMap: { [key in Size]: number } = {
  "extra-small": 14,
  small: 18,
  medium: 18,
  large: 18,
};

const LoadingSpinner = () => {
  return (
    <div className={styles["loading-spinner"]}>
      <svg height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" />
      </svg>
    </div>
  );
};

export default ({
  action,
  destructive,
  disabled,
  icon,
  label,
  loading,
  reverse,
  size,
  type,
}: {
  action: () => void;
  destructive?: boolean;
  disabled?: boolean;
  icon?: Omit<IconProps, "size">;
  label?: string;
  loading?: boolean;
  reverse?: boolean;
  size: Size;
  type: Type;
}) => {
  return (
    <div
      {...classes(styles, [
        "button",
        destructive && "destructive",
        disabled && "disabled",
        loading && "loading",
        reverse && "reverse",
        size,
        type,
      ])}
      onClick={() => !disabled && !loading && action()}
    >
      <div className={styles["contents"]}>
        {icon && (
          <div className={styles["icon"]}>
            <Icon {...icon} size={iconSizeMap[size]} />
          </div>
        )}
        {label}
      </div>
      {loading && <LoadingSpinner />}
    </div>
  );
};
