import { useSetAtom } from "jotai";
import { useEffect } from "react";

import { resetModalLocalStateAtom } from "../atoms";

export default (sourceId: string, resetCallback: () => void) => {
  const setResetModalLocalState = useSetAtom(resetModalLocalStateAtom);

  useEffect(() => {
    setResetModalLocalState((resetMap) => {
      const tempMap = { ...resetMap };
      tempMap[sourceId] = resetCallback;
      return tempMap;
    });
    // only run on initial load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
