import { useAtom } from "jotai";
import { useCallback, useMemo, useState } from "react";

import Modal, { InputRow, ModalType } from ".";
import { Checkbox, createToast, Select, SelectHead, type SelectItem } from "..";
import { createNewCommunicationTypeModalAtom } from "../../atoms";
import {
  useCloseModal,
  useConnectModalResetCallback,
  useWindowSize,
} from "../../hooks";
import { Breakpoint, CommunicationMethodType } from "../../types";
import {
  communicationMethodTypeToIconMap,
  communicationMethodTypeToLabelMap,
  exclude,
  getInvalidFields,
} from "../../utils";

import styles from "../../styles/components/Modals/CreateNewCommunicationTypeModal.module.scss";

const SOURCE_ID = ModalType.CREATE_NEW_COMMUNICATION_TYPE;

const menuItems: SelectItem[] = Object.keys(CommunicationMethodType).map(
  (cm) => ({
    icon: communicationMethodTypeToIconMap[cm as CommunicationMethodType],
    label: communicationMethodTypeToLabelMap[cm as CommunicationMethodType],
    value: cm,
  }),
);

const PreferredCheckbox = ({
  checked,
  toggleChecked,
}: {
  checked: boolean;
  toggleChecked: () => void;
}) => {
  const { breakpoint } = useWindowSize();

  return (
    <div className={styles["preferred-checkbox"]}>
      <Checkbox
        {...{ checked, toggleChecked }}
        showCheckedStyling={false}
        size="small"
      />
      <div className={styles["label"]} onClick={toggleChecked}>
        {breakpoint === Breakpoint.MOBILE
          ? "Set as preferred"
          : "Set as preferred communication"}
      </div>
    </div>
  );
};

const fieldKeys = ["type", "value", "preferred"] as const;
type Field = (typeof fieldKeys)[number];

const defaults = {
  type: menuItems[0].value,
  value: "",
  preferred: false,
};

export default () => {
  const [type, setType] = useState<string>(defaults.type);
  const [value, setValue] = useState<string>(defaults.value);
  const [preferred, setPreferred] = useState<boolean>(defaults.preferred);
  const [invalidFields, setInvalidFields] = useState<Field[]>([]);
  const [modalData, setModalData] = useAtom(
    createNewCommunicationTypeModalAtom,
  );
  const closeModal = useCloseModal();

  const formFieldData = useMemo(
    () => ({
      type: { setValue: setType, value: type },
      value: { setValue, value },
      preferred: { setValue: setPreferred, value: preferred },
    }),
    [preferred, type, value],
  );

  useConnectModalResetCallback(SOURCE_ID, () => {
    // @ts-expect-error: typing complications that are a pain to figure out
    fieldKeys.forEach((key) => formFieldData[key].setValue(defaults[key]));
    setInvalidFields([]);
    setModalData(undefined);
  });

  const handleSubmit = useCallback(() => {
    const invalidFieldsMap: Record<Field, boolean> = {
      type: !type,
      value: !value,
      preferred: false, // optional field, therefore never invalid
    };
    const updatedInvalidFields = getInvalidFields<Field>(invalidFieldsMap);
    setInvalidFields(updatedInvalidFields);

    if (updatedInvalidFields.length) {
      createToast("Error, please fill out all required fields.", "error");
    } else {
      modalData?.createNewCommunicationType(
        type as CommunicationMethodType,
        value,
        preferred,
      );
      closeModal();
    }
  }, [closeModal, modalData, preferred, type, value]);

  return (
    <Modal
      footerLeftElement={
        <PreferredCheckbox
          checked={preferred}
          toggleChecked={() => setPreferred((p) => !p)}
        />
      }
      footerRightButtonAction={handleSubmit}
      footerRightButtonLabel="Create"
      title="New communication type"
      type={SOURCE_ID}
    >
      <div className={styles["rows"]}>
        <div className={styles["row"]}>
          <div className={styles["label"]}>Type</div>
          <Select
            error={invalidFields.includes("type")}
            head={(props) => <SelectHead {...props} items={menuItems} />}
            items={menuItems}
            onOpen={() => setInvalidFields((fields) => exclude(fields, "type"))}
            sameWidth
            setValue={setType}
            size="large"
            type="outline"
            value={type}
          />
        </div>
        <InputRow
          {...{ invalidFields, setInvalidFields, setValue, value }}
          fieldType="value"
          label="Value"
          placeholder="Value"
          type="horizontal"
        />
      </div>
    </Modal>
  );
};
