import { useAtomValue, useSetAtom } from "jotai";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { authSessionAtom } from "../atoms";
import { supabase } from "../utils";

const UNAUTHENTICATED_PATHS = ["/login", "/signup", "/forgot-password"];

const useSupabase = () => {
  const setAuthSession = useSetAtom(authSessionAtom);

  useEffect(() => {
    setAuthSession({ session: null, status: "loading" });

    supabase.auth.getSession().then(({ data: { session } }) => {
      setAuthSession({ session, status: "fetched" });
    });

    const { data } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === "SIGNED_IN") {
        setAuthSession({ session, status: "fetched" });
      } else if (event === "SIGNED_OUT") {
        setAuthSession({ session: null, status: "fetched" });
      }
    });
    return () => data.subscription.unsubscribe();
  }, [setAuthSession]);
};

export default (debug?: boolean) => {
  const authSession = useAtomValue(authSessionAtom);
  const navigate = useNavigate();
  const location = useLocation();
  useSupabase();

  useEffect(() => {
    if (debug) {
      return;
    }

    if (
      authSession.status === "fetched" &&
      authSession.session?.user.role !== "authenticated" &&
      !UNAUTHENTICATED_PATHS.includes(location.pathname)
    ) {
      console.log("not logged in... redirecting to /login");
      navigate("/login");
    }
  }, [authSession, debug, location.pathname, navigate]);

  return { authSession };
};
