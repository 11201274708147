import { useAtom } from "jotai";
import { useCallback, useEffect, useMemo, useState } from "react";

import Modal, { InputRow, ModalType } from ".";
import {
  Button,
  ComboboxItem,
  createToast,
  ShareListInput,
  type SelectItem,
} from "..";
import { createUpdateEventModalDataAtom } from "../../atoms";
import { useCloseModal, useConnectModalResetCallback } from "../../hooks";
import { EventType, IconType, NewEvent_t } from "../../types";
import { classes, getInvalidFields, stripNonValues } from "../../utils";

import styles from "../../styles/components/Modals/CreateUpdateEventModal.module.scss";

const SOURCE_ID = ModalType.CREATE_UPDATE_EVENT;

const deleteEvent = () => {
  console.log("delete event");
};

const eventTypes: SelectItem[] = [
  { icon: IconType.RESTAURANT, label: "Recipe", value: EventType.RECIPE },
  { icon: IconType.EXERCISE, label: "Workout", value: EventType.WORKOUT },
];

const templates: SelectItem[] = [
  { label: "Template #1", value: "template-1" },
  { label: "Template #2", value: "template-2" },
  { label: "Template #3", value: "template-3" },
  { label: "Template #4", value: "template-4" },
  { label: "Template #5", value: "template-5" },
  { label: "Template #6", value: "template-6" },
  { label: "Template #7", value: "template-7" },
  { label: "Template #8", value: "template-8" },
  { label: "Template #9", value: "template-9" },
];

const allTrainees: ComboboxItem[] = [
  { id: "1", image: "https://placehold.co/100x100", name: "Trainee Name #1" },
  { id: "2", image: "https://placehold.co/100x100", name: "Trainee Name #2" },
  { id: "3", image: "https://placehold.co/100x100", name: "Trainee Name #3" },
  { id: "4", image: "https://placehold.co/100x100", name: "Trainee Name #4" },
  { id: "5", image: "https://placehold.co/100x100", name: "Trainee Name #5" },
  { id: "6", image: "https://placehold.co/100x100", name: "Trainee Name #6" },
  { id: "7", image: "https://placehold.co/100x100", name: "Trainee Name #7" },
];

const fieldKeys = [
  "name",
  "duration",
  "event-type",
  "template",
  "date",
  "time",
  "selected-trainee-ids",
] as const;
type Field = (typeof fieldKeys)[number];

const defaults = {
  name: "",
  duration: "0",
  "event-type": eventTypes[0].value,
  template: templates[0].value,
  date: "",
  time: "",
  "selected-trainee-ids": [],
};

export default () => {
  const [name, setName] = useState<string>(defaults.name);
  const [eventType, setEventType] = useState<string>(defaults["event-type"]);
  const [template, setTemplate] = useState<string>(defaults.template);
  const [date, setDate] = useState<string>(defaults.date);
  const [time, setTime] = useState<string>(defaults.time);
  const [duration, setDuration] = useState<string>(defaults.duration);
  const [selectedTraineeIds, setSelectedTraineeIds] = useState<string[]>(
    defaults["selected-trainee-ids"],
  );
  const [invalidFields, setInvalidFields] = useState<Field[]>([]);
  const [modalData, setModalData] = useAtom(createUpdateEventModalDataAtom);
  const closeModal = useCloseModal();

  const formFieldData = useMemo(
    () => ({
      name: { setValue: setName, value: name },
      "event-type": { setValue: setEventType, value: eventType },
      template: { setValue: setTemplate, value: template },
      date: { setValue: setDate, value: date },
      time: { setValue: setTime, value: time },
      duration: { setValue: setDuration, value: duration },
      "selected-trainee-ids": {
        setValue: setSelectedTraineeIds,
        value: selectedTraineeIds,
      },
    }),
    [date, duration, eventType, name, selectedTraineeIds, template, time],
  );

  useConnectModalResetCallback(SOURCE_ID, () => {
    // @ts-expect-error: typing complications that are a pain to figure out
    fieldKeys.forEach((key) => formFieldData[key].setValue(defaults[key]));
    setInvalidFields([]);
    setModalData(undefined);
  });

  const handleSubmit = useCallback(() => {
    const invalidFieldsMap: Record<Field, boolean> = {
      name: !name,
      "event-type": !eventType,
      template: !template,
      date: !date,
      time: !time,
      duration: duration === "0",
      "selected-trainee-ids": false, // optional field, therefore never invalid
    };
    const updatedInvalidFields = getInvalidFields<Field>(invalidFieldsMap);
    setInvalidFields(updatedInvalidFields);

    if (updatedInvalidFields.length) {
      createToast("Error, please fill out all required fields.", "error");
    } else if (modalData) {
      const ids = selectedTraineeIds.map((id) => {
        const idAsNumber = Number(id);
        return Number.isNaN(idAsNumber) ? undefined : idAsNumber;
      });

      const eventData: NewEvent_t = {
        date: new Date(`${date} ${time}`),
        durationMinutes: Number(duration),
        name,
        type: eventType as EventType,
        selectedTraineeIds: stripNonValues<number>(ids),
      };

      if (modalData.id === null) {
        modalData.createEventCallback?.(eventData);
      } else {
        modalData.updateEventCallback?.({
          ...eventData,
          id: modalData.id,
        });
      }

      closeModal();
    }
  }, [
    closeModal,
    date,
    duration,
    eventType,
    modalData,
    name,
    selectedTraineeIds,
    template,
    time,
  ]);

  useEffect(() => {
    if (modalData?.initialEventType) {
      setEventType(modalData?.initialEventType);
    }
  }, [modalData]);

  if (!modalData) {
    return null;
  }

  return (
    <Modal
      footerLeftElement={
        modalData.id ? (
          <Button
            action={deleteEvent}
            destructive
            label="Delete"
            size="medium"
            type="primary"
          />
        ) : undefined
      }
      footerRightButtonAction={handleSubmit}
      footerRightButtonLabel={modalData.id ? "Update" : "Create"}
      title={`${modalData.id ? "Update" : "Create"} event`}
      type={SOURCE_ID}
    >
      <div className={styles["container"]}>
        <div className={styles["inputs"]}>
          <InputRow
            {...{ invalidFields, setInvalidFields }}
            fieldType="name"
            label="Name"
            setValue={setName}
            type="vertical"
            value={name}
          />
          <div {...classes(styles, ["inputs-row", "one-two"])}>
            <InputRow
              {...{ invalidFields, setInvalidFields }}
              dropdownItems={eventTypes}
              fieldType="event-type"
              label="Event type"
              setValue={setEventType}
              type="vertical"
              value={eventType}
            />
            <InputRow
              {...{ invalidFields, setInvalidFields }}
              dropdownItems={templates}
              fieldType="template"
              label="Template"
              setValue={setTemplate}
              type="vertical"
              value={template}
            />
          </div>
          <div {...classes(styles, ["inputs-row", "three-col"])}>
            <InputRow
              {...{ invalidFields, setInvalidFields }}
              fieldType="date"
              inputType="date"
              label="Date"
              setValue={setDate}
              type="vertical"
              value={date}
            />
            <InputRow
              {...{ invalidFields, setInvalidFields }}
              fieldType="time"
              inputType="time"
              label="Time"
              setValue={setTime}
              type="vertical"
              value={time}
            />
            <InputRow
              {...{ invalidFields, setInvalidFields }}
              fieldType="duration"
              label="Duration"
              numeric
              numericPositive
              setValue={setDuration}
              type="vertical"
              value={duration}
            />
          </div>
        </div>
        <div className={styles["share"]}>
          <div className={styles["label"]}>Share with trainees</div>
          <ShareListInput
            allItems={allTrainees}
            selectedIds={selectedTraineeIds}
            setSelectedIds={setSelectedTraineeIds}
          />
        </div>
      </div>
    </Modal>
  );
};
