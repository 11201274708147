import {
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  useCallback,
  useState,
} from "react";

import { Button } from ".";
import { IconType } from "../types";

import styles from "../styles/components/EditWorkoutOrRecipeTitle.module.scss";

export default ({
  setTitle,
  title,
}: {
  setTitle: Dispatch<SetStateAction<string>>;
  title: string;
}) => {
  const [isEditing, setEditing] = useState<boolean>(false);
  const [value, setValue] = useState<string>(title);

  const save = useCallback(() => {
    if (!value) {
      console.error("Title cannot be empty");
      return;
    }

    setTitle(value);
    setEditing(false);
  }, [setTitle, value]);

  const cancel = useCallback(() => {
    setEditing(false);
    setValue(title);
  }, [title]);

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        save();
      } else if (e.key === "Escape") {
        cancel();
      }
    },
    [cancel, save],
  );

  if (isEditing) {
    return (
      <div className={styles["component"]}>
        <input
          {...{ value }}
          onChange={(e) => setValue(e.target.value)}
          onKeyDown={handleKeyDown}
          type="text"
        />
        <div className={styles["buttons"]}>
          <Button
            action={save}
            icon={{ filled: true, type: IconType.SAVE }}
            label="Save"
            size="extra-small"
            type="primary"
          />
          <Button
            action={cancel}
            destructive
            icon={{ type: IconType.CLOSE }}
            label="Cancel"
            size="extra-small"
            type="primary"
          />
        </div>
      </div>
    );
  }

  return (
    <div className={styles["component"]}>
      <div className={styles["title"]}>{title}</div>
      <Button
        action={() => setEditing(true)}
        icon={{ filled: true, type: IconType.EDIT }}
        label="Edit"
        size="extra-small"
        type="primary"
      />
    </div>
  );
};
