import {
  ComponentType,
  createElement,
  FunctionComponentElement,
  LazyExoticComponent,
} from "react";

import { usePageData } from "../hooks";
import { UserType } from "../types";

const DEBUG = true;
const USER_TYPE = UserType.USER;
const INVALID_USER_TYPE_ERROR_MESSAGE = "invalid user type";

export interface PageType {
  userType?: UserType;
}

export const PageVariantByUserType = (
  props: PageType &
    (
      | { all: () => JSX.Element }
      | {
          trainer?: () => JSX.Element;
          user?: () => JSX.Element;
        }
    ),
) => {
  if ("all" in props) {
    if (Object.values(UserType).includes(props.userType as UserType)) {
      return createElement(props.all);
    }

    throw Error(INVALID_USER_TYPE_ERROR_MESSAGE);
  }

  if (props.trainer && props.userType === UserType.TRAINER) {
    return createElement(props.trainer);
  }

  if (props.user && props.userType === UserType.USER) {
    return createElement(props.user);
  }

  throw Error(INVALID_USER_TYPE_ERROR_MESSAGE);
};

export default ({
  element,
}: {
  element: LazyExoticComponent<ComponentType<PageType>>;
}): FunctionComponentElement<PageType> => {
  const { authSession } = usePageData(DEBUG);

  if (DEBUG) {
    console.log(USER_TYPE, authSession);
  }

  return createElement(element, { userType: USER_TYPE });
};
