import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { useEffect, useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import styles from "../styles/components/ImagePicker.module.scss";

// Register the plugins
registerPlugin(
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
);

enum FileStatus {
  INIT = 1,
  IDLE = 2,
  PROCESSING_QUEUED = 9,
  PROCESSING = 3,
  PROCESSING_COMPLETE = 5,
  PROCESSING_ERROR = 6,
  PROCESSING_REVERT_ERROR = 10,
  LOADING = 7,
  LOAD_ERROR = 8,
}

enum FileOrigin {
  INPUT = 1,
  LIMBO = 2,
  LOCAL = 3,
}

type ActualFileObject = Blob & {
  readonly lastModified: number;
  readonly name: string;
};

interface FilePondInitialFile {
  /** The server file reference. */
  source: string;
  options: {
    /** Origin of file being added. */
    type: "input" | "limbo" | "local";
    /** Mock file information. */
    file?: {
      name?: string;
      size?: number;
      type?: string;
    };
    /** File initial metadata. */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    metadata?: { [key: string]: any };
  };
}

interface FilePondFile {
  /** Returns the ID of the file. */
  id: string;
  /** Returns the server id of the file. */
  serverId: string;
  /** Returns the source of the file. */
  source: ActualFileObject | string;
  /** Returns the origin of the file. */
  origin: FileOrigin;
  /** Returns the current status of the file. */
  status: FileStatus;
  /** Returns the File object. */
  file: ActualFileObject;
  /** Returns the file extensions. */
  fileExtension: string;
  /** Returns the size of the file. */
  fileSize: number;
  /** Returns the type of the file. */
  fileType: string;
  /** Returns the full name of the file. */
  filename: string;
  /** Returns the name of the file without extension. */
  filenameWithoutExtension: string;

  /** Aborts loading of this file */
  abortLoad: () => void;
  /** Aborts processing of this file */
  abortProcessing: () => void;
  /**
   * Retrieve metadata saved to the file, pass a key to retrieve
   * a specific part of the metadata (e.g. 'crop' or 'resize').
   * If no key is passed, the entire metadata object is returned.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getMetadata: (key?: string) => any;
  /** Add additional metadata to the file */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setMetadata: (key: string, value: any, silent?: boolean) => void;
}

export default ({
  noBackgroundColor,
  update,
}: {
  noBackgroundColor?: boolean;
  update?: (newImageUrl: string) => void;
}) => {
  const [files, setFiles] = useState<
    (string | FilePondInitialFile | Blob | ActualFileObject)[]
  >([]);

  useEffect(() => {
    if (!update || !files.length) {
      return;
    }

    update((files[0] as File).name);
  }, [files, update]);

  console.log("files", files);

  return (
    <div
      className={styles["component"]}
      style={{
        backgroundColor: noBackgroundColor ? "transparent" : "var(--sky-1000)",
      }}
    >
      <FilePond
        acceptedFileTypes={["image/png", "image/jpeg"]}
        files={files}
        labelIdle="Click to upload or drag file"
        maxFileSize="1MB"
        maxFiles={1}
        name="file"
        onupdatefiles={(fileItems: FilePondFile[]) =>
          setFiles(fileItems.map((fileItem) => fileItem.file as File))
        }
      />
    </div>
  );
};
