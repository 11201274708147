import { Icon } from ".";
import { IconType } from "../types";
import { classes } from "../utils";

import styles from "../styles/components/Checkbox.module.scss";

type Size = "small" | "large";

const iconSize: { [key in Size]: number } = {
  small: 18,
  large: 20,
};

export default ({
  checked,
  showCheckedStyling = true,
  size,
  toggleChecked,
}: {
  checked: boolean;
  showCheckedStyling?: boolean;
  size: Size;
  toggleChecked: () => void;
}) => {
  return (
    <div
      {...classes(styles, [
        "component",
        checked && showCheckedStyling && "checked",
        size,
      ])}
      onClick={toggleChecked}
    >
      {checked && <Icon filled size={iconSize[size]} type={IconType.CHECK} />}
    </div>
  );
};
