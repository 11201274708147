import { useMemo } from "react";

import { Icon } from ".";
import { ExerciseStatus, IconType } from "../types";
import { classes } from "../utils";

import styles from "../styles/components/ExerciseStatusIndicator.module.scss";

const iconSizeMap = {
  small: 12,
  medium: 16,
  large: 18,
};

const statusToClassMap: { [key in ExerciseStatus]: string } = {
  [ExerciseStatus.COMPLETED]: "completed",
  [ExerciseStatus.FUTURE]: "future",
  [ExerciseStatus.INCOMPLETE]: "incomplete",
  [ExerciseStatus.IN_PROGRESS]: "in-progress",
  [ExerciseStatus.SKIPPED]: "skipped",
};

export default ({
  size,
  status,
}: {
  size: "small" | "medium" | "large";
  status: ExerciseStatus;
}) => {
  const iconSize = useMemo(() => iconSizeMap[size], [size]);
  const iconType = useMemo(
    () =>
      ({
        [ExerciseStatus.COMPLETED]: IconType.CHECK,
        [ExerciseStatus.FUTURE]: undefined,
        [ExerciseStatus.INCOMPLETE]: IconType.INCOMPLETE_CIRCLE,
        [ExerciseStatus.IN_PROGRESS]: IconType.SCHEDULE,
        [ExerciseStatus.SKIPPED]: IconType.CLOSE,
      })[status],
    [status],
  );

  return (
    <div {...classes(styles, ["component", statusToClassMap[status], size])}>
      {iconType ? (
        <Icon size={iconSize} type={iconType} />
      ) : (
        <div className={styles["future"]} />
      )}
    </div>
  );
};
