import {
  Tooltip,
  TooltipAnchor,
  TooltipArrow,
  TooltipProvider,
} from "@ariakit/react";
import { ReactNode } from "react";

import styles from "../styles/components/Tooltip.module.scss";

export default ({
  children,
  label,
}: {
  children: ReactNode;
  label?: string;
}) => {
  if (!label) {
    return <>{children}</>;
  }

  return (
    <TooltipProvider>
      <TooltipAnchor>{children}</TooltipAnchor>
      <Tooltip className={styles["component"]} gutter={2}>
        <TooltipArrow />
        {label}
      </Tooltip>
    </TooltipProvider>
  );
};
