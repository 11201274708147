import { useAtom } from "jotai";
import { useCallback, useMemo, useState } from "react";

import Modal, { InputRow, ModalType } from ".";
import { createToast } from "..";
import { addFilterModalDataAtom } from "../../atoms";
import { useCloseModal, useConnectModalResetCallback } from "../../hooks";
import { getInvalidFields } from "../../utils";

import styles from "../../styles/components/Modals/AddFilterModal.module.scss";

const SOURCE_ID = ModalType.ADD_FILTER;

const properties = [
  { label: "Author", value: "author" },
  { label: "Title", value: "title" },
];

const operators = [
  { label: "=", value: "eq" },
  { label: "≠", value: "ne" },
  { label: ">", value: "gt" },
  { label: "<", value: "lt" },
  { label: "contains", value: "contains" },
  { label: "does not contain", value: "does not contain" },
];

const exclusiveNumericOperators = ["lt", "gt"];

const fieldKeys = ["property", "operator", "value"] as const;
type Field = (typeof fieldKeys)[number];

const defaults = {
  property: properties[0].value,
  operator: operators[0].value,
  value: "",
};

export default () => {
  const [property, setProperty] = useState<string>(defaults.property);
  const [operator, setOperator] = useState<string>(defaults.operator);
  const [value, setValue] = useState<string>(defaults.value);
  const [invalidFields, setInvalidFields] = useState<Field[]>([]);
  const [modalData, setModalData] = useAtom(addFilterModalDataAtom);
  const closeModal = useCloseModal();

  const formFieldData = useMemo(
    () => ({
      operator: { setValue: setOperator, value: operator },
      property: { setValue: setProperty, value: property },
      value: { setValue, value },
    }),
    [operator, property, value],
  );

  useConnectModalResetCallback(SOURCE_ID, () => {
    fieldKeys.forEach((key) => formFieldData[key].setValue(defaults[key]));
    setInvalidFields([]);
    setModalData(undefined);
  });

  const handleSubmit = useCallback(() => {
    const invalidFieldsMap: Record<Field, boolean> = {
      property: !property,
      operator: !operator,
      value: !value,
    };
    const updatedInvalidFields = getInvalidFields<Field>(invalidFieldsMap);
    setInvalidFields(updatedInvalidFields);

    if (updatedInvalidFields.length) {
      createToast("Error, please fill out all required fields.", "error");
      return;
    }

    const matchingProperty = properties.find((p) => p.value === property);
    const matchingOperator = operators.find((o) => o.value === operator);

    if (!matchingProperty || !matchingOperator) {
      createToast("Error, invalid dropdown selections.", "error");
      return;
    }

    const isNumericComparison = exclusiveNumericOperators.includes(
      matchingOperator.value,
    );

    modalData?.addFilter({
      id: `${property}_${operator}_${value}`,
      label: `${matchingProperty.label} ${matchingOperator.label} ${isNumericComparison ? "" : "“"}${value}${isNumericComparison ? "" : "”"}`,
    });
    closeModal();
  }, [closeModal, modalData, operator, property, value]);

  return (
    <Modal
      footerRightButtonAction={handleSubmit}
      footerRightButtonLabel="Add"
      title="Add filter"
      type={SOURCE_ID}
    >
      <div className={styles["rows"]}>
        <InputRow
          {...{ invalidFields, setInvalidFields }}
          dropdownItems={properties}
          fieldType="property"
          label="Filter by"
          setValue={setProperty}
          type="horizontal"
          value={property}
        />
        <InputRow
          {...{ invalidFields, setInvalidFields }}
          dropdownItems={operators}
          fieldType="operator"
          label=""
          setValue={setOperator}
          type="horizontal"
          value={operator}
        />
        <InputRow
          {...{ invalidFields, setInvalidFields, value }}
          fieldType="value"
          label="Value"
          placeholder="Value"
          setValue={setValue}
          type="horizontal"
        />
      </div>
    </Modal>
  );
};
