import {
  AddFilterModal,
  ConfirmationModal,
  CreateNewCommunicationTypeModal,
  CreateUpdateEventModal,
  ItinerarySidebar,
  Navbar,
  UpdateEmailAddressModal,
  UpdatePasswordModal,
} from ".";
import { usePageTitle, useWindowSize } from "../hooks";
import { Breakpoint, EventType } from "../types";

import styles from "../styles/components/PageContainer.module.scss";

export default ({
  children,
  itinerarySidebar: { show, filter },
  title,
}: {
  children: JSX.Element;
  itinerarySidebar: { show?: boolean; filter?: EventType };
  title?: string;
}) => {
  usePageTitle(title);
  const { breakpoint } = useWindowSize();

  return (
    <div className={styles["component"]}>
      <Navbar />
      <div className={styles["content"]}>{children}</div>
      {show && breakpoint === Breakpoint.DESKTOP && (
        <ItinerarySidebar {...{ filter }} />
      )}

      {/* all modals */}
      <AddFilterModal />
      <ConfirmationModal />
      <CreateNewCommunicationTypeModal />
      <CreateUpdateEventModal />
      <UpdateEmailAddressModal />
      <UpdatePasswordModal />
    </div>
  );
};
