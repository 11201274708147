import { useCallback, useMemo, useState } from "react";

import Modal, { InputRow, ModalType } from ".";
import { createToast } from "..";
import { ConfirmationModalActionAtom } from "../../atoms";
import {
  useConfirmationModalAction,
  useConnectModalResetCallback,
  useOpenConfirmationModal,
} from "../../hooks";
import { getInvalidFields } from "../../utils";

import styles from "../../styles/components/Modals/UpdatePasswordModal.module.scss";

const SOURCE_ID = ModalType.UPDATE_PASSWORD;

const updatePassword = () => {
  console.log("update password");
};

const updatePasswordConfirmationModal: ConfirmationModalActionAtom = {
  confirmButtonLabel: "Change",
  description: "Are you sure you want to change your password?",
};

const fieldKeys = ["password", "confirmed-password"] as const;
type Field = (typeof fieldKeys)[number];

const defaults = {
  password: "",
  "confirmed-password": "",
};

export default () => {
  const [password, setPassword] = useState<string>(defaults.password);
  const [confirmedPassword, setConfirmedPassword] = useState<string>(
    defaults["confirmed-password"],
  );
  const [invalidFields, setInvalidFields] = useState<Field[]>([]);
  const openConfirmationModal = useOpenConfirmationModal();
  useConfirmationModalAction(SOURCE_ID, "confirmed", updatePassword);

  const formFieldData = useMemo(
    () => ({
      password: { setValue: setPassword, value: password },
      "confirmed-password": {
        setValue: setConfirmedPassword,
        value: confirmedPassword,
      },
    }),
    [confirmedPassword, password],
  );

  useConnectModalResetCallback(SOURCE_ID, () => {
    fieldKeys.forEach((key) => formFieldData[key].setValue(defaults[key]));
    setInvalidFields([]);
  });

  const handleSubmit = useCallback(() => {
    const invalidFieldsMap: Record<Field, boolean> = {
      password: !password,
      "confirmed-password":
        !confirmedPassword || password !== confirmedPassword,
    };
    const updatedInvalidFields = getInvalidFields<Field>(invalidFieldsMap);
    setInvalidFields(updatedInvalidFields);

    if (confirmedPassword && password !== confirmedPassword) {
      createToast("Error, passwords do not match.", "error");
    } else if (updatedInvalidFields.length) {
      createToast("Error, please fill out all required fields.", "error");
    } else {
      openConfirmationModal(updatePasswordConfirmationModal, SOURCE_ID);
    }
  }, [confirmedPassword, openConfirmationModal, password]);

  return (
    <Modal
      footerRightButtonAction={handleSubmit}
      footerRightButtonLabel="Update"
      title="Update password"
      type={SOURCE_ID}
    >
      <div className={styles["rows"]}>
        <InputRow
          {...{ invalidFields, setInvalidFields }}
          fieldType="password"
          inputType="password"
          label="New password"
          placeholder="Password"
          setValue={setPassword}
          type="vertical"
          value={password}
        />
        <InputRow
          {...{ invalidFields, setInvalidFields }}
          fieldType="confirmed-password"
          inputType="password"
          label="Confirm new password"
          placeholder="Password"
          setValue={setConfirmedPassword}
          type="vertical"
          value={confirmedPassword}
        />
      </div>
    </Modal>
  );
};
