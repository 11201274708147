import { Dispatch, SetStateAction } from "react";

import {
  Input,
  Select,
  SelectHead,
  SelectItem,
  SelectSize,
  SelectType,
} from ".";
import { InputType } from "../types";
import { exclude } from "../utils";

import styles from "../styles/components/FormField.module.scss";

export default <T,>({
  fieldType,
  inputProps,
  inputType,
  invalidFields,
  label,
  setInvalidFields,
  setValue,
  value,
}: {
  fieldType: T;
  invalidFields: T[];
  label: string;
  setInvalidFields: Dispatch<SetStateAction<T[]>>;
  setValue: Dispatch<SetStateAction<string>>;
  value: string;
} & (
  | { inputType: "input"; inputProps?: { type?: InputType } }
  | {
      inputType: "select";
      inputProps: {
        items: SelectItem[];
        sameWidth?: boolean;
        size: SelectSize;
        type: SelectType;
      };
    }
)) => {
  return (
    <div className={styles["component"]}>
      <div className={styles["label"]}>{label}</div>
      {inputType === "input" && (
        <Input
          {...{ setValue, value }}
          error={invalidFields.includes(fieldType)}
          inputType={inputProps?.type}
          onFocus={() =>
            setInvalidFields((fields) => exclude(fields, fieldType))
          }
        />
      )}
      {inputType === "select" && (
        <Select
          {...{ setValue, value }}
          error={invalidFields.includes(fieldType)}
          head={(props) => <SelectHead {...props} items={inputProps.items} />}
          items={inputProps.items}
          onOpen={() =>
            setInvalidFields((fields) => exclude(fields, fieldType))
          }
          sameWidth={inputProps.sameWidth}
          size={inputProps.size}
          type={inputProps.type}
        />
      )}
    </div>
  );
};
