import { atom } from "jotai";

import { ModalType } from "./components";
import {
  AuthSession,
  CommunicationMethodType,
  Event_t,
  EventType,
  FilterableCardFilterTag,
  IconType,
  NewEvent_t,
} from "./types";

export const authSessionAtom = atom<AuthSession>({
  session: null,
  status: "not-fetched",
});

export interface ConfirmationModalActionAtom {
  confirmButtonLabel: string;
  description: string;
  destructive?: boolean;
  icon?: IconType;
  preventCancel?: boolean;
}

export const confirmationModalActionAtom = atom<
  ConfirmationModalActionAtom | undefined
>(undefined);

export type ConfirmationModalStatus =
  | "open"
  | "confirmed"
  | "canceled"
  | undefined;

export const confirmationModalStatusAtom =
  atom<ConfirmationModalStatus>(undefined);

export const confirmationModalSourceAtom = atom<string | undefined>(undefined);

export const currentOpenModalAtom = atom<ModalType | undefined>(undefined);

export const createUpdateEventModalDataAtom = atom<
  | {
      createEventCallback?: (newEvent: NewEvent_t) => void;
      updateEventCallback?: (updatedEvent: Event_t) => void;
      id: number | null;
      initialEventType?: EventType;
    }
  | undefined
>(undefined);

export const addFilterModalDataAtom = atom<
  { addFilter: (newFilter: FilterableCardFilterTag) => void } | undefined
>(undefined);

export const createNewCommunicationTypeModalAtom = atom<
  | {
      createNewCommunicationType: (
        type: CommunicationMethodType,
        value: string,
        preferred: boolean,
      ) => void;
    }
  | undefined
>(undefined);

export const startEventSourceAtom = atom<string | undefined>(undefined);

// key: modal ID
// value: callback for resetting local state of given modal
export const resetModalLocalStateAtom = atom<Record<string, () => void>>({});
