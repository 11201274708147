import { ReactNode } from "react";

import styles from "../styles/components/ScrollingWrapper.module.scss";

export default ({
  children,
  gap = 16,
}: {
  children: ReactNode;
  gap?: number;
}) => {
  return (
    <div
      className={styles["component"]}
      style={{
        gap,
        marginInline: "calc(-1 * var(--container-horizontal-padding))",
        paddingInline: "var(--container-horizontal-padding)",
        width: `calc(100% + calc(var(--container-horizontal-padding) * 2))`,
        WebkitMaskImage: `linear-gradient(to right, transparent 0px, black var(--container-horizontal-padding), black calc(100% - var(--container-horizontal-padding)), transparent 100%)`,
      }}
    >
      {children}
    </div>
  );
};
