import { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Icon } from ".";
import { useWindowSize } from "../hooks";
import { Breakpoint, IconType, type IconProps } from "../types";
import { classes, logout, wait } from "../utils";

import styles from "../styles/components/Navbar.module.scss";

interface MenuItem {
  action?: () => void;
  destructive?: boolean;
  icon: Omit<IconProps, "size">;
  label: string;
  path?: string;
}

const topMenuItems: MenuItem[] = [
  {
    icon: { type: IconType.HOME },
    label: "Home",
    path: "/",
  },
  {
    icon: { type: IconType.EXERCISE },
    label: "Workouts",
    path: "/workouts",
  },
  {
    icon: { type: IconType.RESTAURANT },
    label: "Recipes",
    path: "/recipes",
  },
  {
    icon: { type: IconType.CALENDAR },
    label: "Calendar",
    path: "/calendar",
  },
];

const LoadingScreen = () => {
  const [visible, setVisible] = useState<boolean>(false);

  // add visible class to element on initial load to trigger transition
  useEffect(() => setVisible(true), []);

  return (
    <div {...classes(styles, ["loading-screen", visible && "visible"])}>
      <div className={styles["spinner"]}></div>
    </div>
  );
};

const MenuItem = ({ action, destructive, icon, label, path }: MenuItem) => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div
      {...classes(styles, [
        "menu-item",
        destructive && "destructive",
        location.pathname === path && "active",
      ])}
      onClick={path ? () => navigate(path) : action}
    >
      <Icon {...icon} size={24} />
      {label}
    </div>
  );
};

const MenuItems = ({ list }: { list: MenuItem[] }) => {
  return (
    <div className={styles["menu-items"]}>
      {list.map((menuItem) => (
        <MenuItem {...menuItem} key={menuItem.label} />
      ))}
    </div>
  );
};

export default () => {
  const [showLoadingScreen, setShowLoadingScreen] = useState<boolean>(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { breakpoint } = useWindowSize();

  const logoutAction = useCallback(async () => {
    setShowLoadingScreen(true);
    await wait(2);
    await logout();
    setShowLoadingScreen(false);
    navigate("/");
  }, [navigate]);

  const bottomMenuItems: MenuItem[] = useMemo(
    () => [
      {
        icon: { type: IconType.ACCOUNT_CIRCLE },
        label: "Profile",
        path: "/profile",
      },
      {
        action: logoutAction,
        destructive: true,
        icon: { type: IconType.LOGOUT },
        label: "Log out",
      },
    ],
    [logoutAction],
  );

  useEffect(() => {
    if (
      mobileMenuOpen &&
      [Breakpoint.DESKTOP, Breakpoint.SMALL_DESKTOP].includes(breakpoint)
    ) {
      setMobileMenuOpen(false);
    }
  }, [breakpoint, mobileMenuOpen]);

  return (
    <>
      <div
        {...classes(styles, [
          "mobile-menu-background",
          mobileMenuOpen && "mobile-visible",
        ])}
        onClick={() => setMobileMenuOpen(false)}
      />
      <div
        {...classes(styles, ["component", mobileMenuOpen && "mobile-visible"])}
      >
        <div
          className={styles["close-button"]}
          onClick={() => setMobileMenuOpen(false)}
        >
          <Icon size={24} type={IconType.CLOSE} />
        </div>
        <div className={styles["top"]}>
          <div className={styles["logo-container"]}>
            <Link to="/">
              <img className={styles["logo"]} />
            </Link>
          </div>
          <MenuItems list={topMenuItems} />
        </div>
        <div className={styles["bottom"]}>
          <MenuItems list={bottomMenuItems} />
        </div>
        {showLoadingScreen && createPortal(<LoadingScreen />, document.body)}
      </div>
      <div className={styles["mobile-menu"]}>
        <div
          className={styles["button"]}
          onClick={() => setMobileMenuOpen((o) => !o)}
        >
          <Icon size={24} type={IconType.MENU} />
        </div>
      </div>
    </>
  );
};
