import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default (title?: string) => {
  const location = useLocation();

  useEffect(() => {
    document.title = title ? `${title} | FitSynth` : "FitSynth";
  }, [location, title]);
};
