import {
  Menu,
  MenuButton,
  MenuItem,
  MenuProvider,
  MenuStore,
  useMenuStore,
} from "@ariakit/react";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useRef,
  useState,
} from "react";

import { Icon } from ".";
import { useClickOutside } from "../hooks";
import { IconType } from "../types";
import { classes } from "../utils";

import styles from "../styles/components/Menu.module.scss";

export interface MenuItem {
  destructive?: boolean;
  disabled?: boolean;
  icon?: IconType;
  label: string;
  onClick?: () => void;
}

type Placement =
  | "bottom"
  | "bottom-end"
  | "bottom-start"
  | "left"
  | "left-end"
  | "left-start"
  | "right"
  | "right-end"
  | "right-start"
  | "top"
  | "top-end"
  | "top-start";

export default ({
  children, // button that triggers the menu to open/close
  items,
  open,
  placement = "bottom-end",
  setOpen,
  store,
  zIndex,
}: {
  children: ReactNode;
  items: MenuItem[];
  open?: boolean;
  placement?: Placement;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  store?: MenuStore;
  zIndex?: number;
}) => {
  return (
    <MenuProvider {...{ open, placement, setOpen, store }}>
      {children}
      <Menu
        className={styles["menu"]}
        gutter={4}
        hideOnInteractOutside
        portal
        style={{ zIndex }}
      >
        {items.map(({ destructive, disabled, icon, label, onClick }) => (
          <MenuItem
            {...classes(styles, [
              "item",
              destructive && "destructive",
              disabled && "disabled",
            ])}
            key={label}
            onClick={() => !disabled && onClick?.()}
          >
            {icon && <Icon filled size={14} type={icon} />}
            {label}
          </MenuItem>
        ))}
      </Menu>
    </MenuProvider>
  );
};

export const useCustomTriggerMenu = () => {
  const [open, setOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  useClickOutside(ref, () => setOpen(false));

  const toggleOpen = useCallback(() => setOpen((o) => !o), []);

  return { open, ref, setOpen, toggleOpen };
};

export { MenuButton, useMenuStore };
