import { Link } from "react-router-dom";

import { Icon } from ".";
import { FeaturedCardType, IconType } from "../types";
import { classes, getPathFromId } from "../utils";

import styles from "../styles/components/FeaturedCard.module.scss";

export default ({ author, colorStyle, id, tags, title }: FeaturedCardType) => {
  return (
    <Link
      {...classes(styles, ["component", colorStyle])}
      to={getPathFromId(id)}
    >
      <div className={styles["top"]}>
        <div className={styles["title"]}>{title}</div>
        {author && <div className={styles["author"]}>{author}</div>}
      </div>
      <div className={styles["bottom"]}>
        <div className={styles["tags"]}>
          {tags.map((tag, index) => (
            <div className={styles["tag"]} key={index}>
              {tag}
            </div>
          ))}
        </div>
        <div className={styles["play-button"]}>
          <Icon filled size={32} type={IconType.PLAY_ARROW} />
        </div>
      </div>
    </Link>
  );
};
