import { useAtomValue } from "jotai";
import {
  Dispatch,
  KeyboardEvent,
  ReactNode,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { createPortal } from "react-dom";

import { Button, Icon, Input, Select, SelectHead, SelectItem } from "..";
import { currentOpenModalAtom } from "../../atoms";
import { useCloseModal } from "../../hooks";
import { IconType, InputType } from "../../types";
import { classes, exclude } from "../../utils";

import styles from "../../styles/components/Modals/index.module.scss";

export enum ModalType {
  ADD_FILTER = "ADD_FILTER",
  CREATE_NEW_COMMUNICATION_TYPE = "CREATE_NEW_COMMUNICATION_TYPE",
  CREATE_UPDATE_EVENT = "CREATE_UPDATE_EVENT",
  UPDATE_EMAIL_ADDRESS = "UPDATE_EMAIL_ADDRESS",
  UPDATE_PASSWORD = "UPDATE_PASSWORD",
}

export const InputRow = <T,>({
  dropdownItems,
  fieldType,
  inputType,
  invalidFields,
  label,
  numeric,
  numericPositive,
  placeholder,
  setInvalidFields,
  setValue,
  type,
  value,
}: {
  dropdownItems?: SelectItem[];
  fieldType: T;
  inputType?: InputType;
  invalidFields: T[];
  label: string;
  numeric?: boolean;
  numericPositive?: boolean;
  placeholder?: string;
  setInvalidFields: Dispatch<SetStateAction<T[]>>;
  setValue: Dispatch<SetStateAction<string>>;
  type: "horizontal" | "vertical";
  value: string;
}) => {
  return (
    <div {...classes(styles, ["input-row", type])}>
      <div className={styles["label"]}>{label}</div>
      {dropdownItems ? (
        <Select
          {...{ setValue, value }}
          error={invalidFields.includes(fieldType)}
          head={(props) => <SelectHead {...props} items={dropdownItems} />}
          items={dropdownItems}
          onOpen={() =>
            setInvalidFields((fields) => exclude(fields, fieldType))
          }
          sameWidth
          size="large"
          type="outline"
        />
      ) : (
        <Input
          {...{
            inputType,
            numeric,
            numericPositive,
            placeholder,
            setValue,
            value,
          }}
          error={invalidFields.includes(fieldType)}
          onFocus={() =>
            setInvalidFields((fields) => exclude(fields, fieldType))
          }
          size="medium"
        />
      )}
    </div>
  );
};

const useAutoFocus = (isOpen: boolean, ref: RefObject<HTMLDivElement>) => {
  useEffect(() => {
    if (isOpen) {
      ref.current?.focus();
    }
  }, [isOpen, ref]);

  return null;
};

export default ({
  children,
  footerLeftElement,
  footerRightButtonAction,
  footerRightButtonLabel,
  title,
  type,
}: {
  children: ReactNode;
  footerLeftElement?: ReactNode;
  footerRightButtonAction?: () => void;
  footerRightButtonLabel: string;
  title: string;
  type: ModalType;
}) => {
  const currentOpenModal = useAtomValue(currentOpenModalAtom);
  const closeModal = useCloseModal();
  const [backgroundMouseDown, setBackgroundMouseDown] =
    useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  useAutoFocus(currentOpenModal === type, ref);

  const onBackgroundMouseUp = useCallback(() => {
    if (backgroundMouseDown) {
      closeModal();
    }
    setBackgroundMouseDown(false);
  }, [backgroundMouseDown, closeModal]);

  const handleKeyUp = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        closeModal();
      }
    },
    [closeModal],
  );

  if (currentOpenModal !== type) {
    return null;
  }

  return createPortal(
    <div
      {...{ ref }}
      className={styles["modal-background"]}
      onKeyUp={handleKeyUp}
      onMouseDown={() => setBackgroundMouseDown(true)}
      onMouseUp={onBackgroundMouseUp}
      tabIndex={-1}
    >
      <div
        className={styles["modal-container"]}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <div className={styles["header"]}>
          <div className={styles["title"]}>{title}</div>
          <div className={styles["close-button"]} onClick={closeModal}>
            <Icon size={20} type={IconType.CLOSE} />
          </div>
        </div>
        <div className={styles["content"]}>{children}</div>
        <div className={styles["footer"]}>
          <div className={styles["footer-left"]}>{footerLeftElement}</div>
          <div className={styles["footer-right"]}>
            <Button
              action={() => footerRightButtonAction?.()}
              label={footerRightButtonLabel}
              size="medium"
              type="primary"
            />
          </div>
        </div>
      </div>
    </div>,
    document.body,
  );
};
