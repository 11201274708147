import { useSetAtom } from "jotai";
import { Dispatch, ReactNode, SetStateAction, useCallback } from "react";

import { Button, Icon, Input, ModalType } from ".";
import { addFilterModalDataAtom, currentOpenModalAtom } from "../atoms";
import { FilterableCardFilterTag, IconType } from "../types";
import { append } from "../utils";

import styles from "../styles/components/FilterableCardGrid.module.scss";

export default ({
  children,
  filterTags,
  inputPlaceholder,
  inputValue,
  isEmpty,
  setFilterTags,
  setInputValue,
}: {
  children: ReactNode;
  filterTags: FilterableCardFilterTag[];
  inputPlaceholder: string;
  inputValue: string;
  isEmpty?: boolean;
  setFilterTags: Dispatch<SetStateAction<FilterableCardFilterTag[]>>;
  setInputValue: Dispatch<SetStateAction<string>>;
}) => {
  const setCurrentOpenModal = useSetAtom(currentOpenModalAtom);
  const setAddFilterModalData = useSetAtom(addFilterModalDataAtom);

  const removeTag = useCallback(
    (id: string) =>
      setFilterTags((tags) => tags.filter((tag) => tag.id !== id)),
    [setFilterTags],
  );

  const addFilter = useCallback(
    (newFilter: FilterableCardFilterTag) =>
      setFilterTags((ft) => append(ft, newFilter)),
    [setFilterTags],
  );

  const openModal = useCallback(() => {
    setAddFilterModalData({ addFilter });
    setCurrentOpenModal(ModalType.ADD_FILTER);
  }, [addFilter, setAddFilterModalData, setCurrentOpenModal]);

  return (
    <div className={styles["component"]}>
      <div className={styles["filters-and-search"]}>
        <div className={styles["filters"]}>
          {filterTags.map(({ id, label }) => (
            <div className={styles["filter-tag"]} key={id}>
              <div className={styles["filter-tag-label"]}>{label}</div>
              <div
                className={styles["filter-tag-delete-button"]}
                onClick={() => removeTag(id)}
              >
                <Icon size={16} type={IconType.CLOSE} />
              </div>
            </div>
          ))}
          <Button
            action={openModal}
            icon={{ type: IconType.ADD }}
            label="Add filter"
            size="small"
            type="outline"
          />
        </div>
        <div className={styles["search-input"]}>
          <Input
            icon={IconType.SEARCH}
            placeholder={inputPlaceholder}
            setValue={setInputValue}
            value={inputValue}
          />
        </div>
      </div>
      <div className={styles[isEmpty ? "empty-grid" : "grid"]}>{children}</div>
    </div>
  );
};
