import { Event_t, EventType } from "./types";

export const SUPABASE_ANON_KEY =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InFncWxpbG9mY2V2bnd6ZnptZ3FoIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTAxNTc3MDEsImV4cCI6MjAwNTczMzcwMX0.x5sHljpx7ConO78W1NviyMBb9E_OP3xDrykvKnRomXQ";
export const SUPABASE_PROJECT_URL = "https://qgqlilofcevnwzfzmgqh.supabase.co";

export const initEvents: Event_t[] = [
  {
    date: new Date(2024, 7, 30, 2, 0, 0),
    durationMinutes: 45,
    id: 0,
    name: "Workout Name Goes Here",
    selectedTraineeIds: [],
    type: EventType.WORKOUT,
  },
  {
    date: new Date(2024, 7, 30, 4, 0, 0),
    durationMinutes: 30,
    id: 1,
    name: "Recipe Name Goes Here",
    selectedTraineeIds: [],
    type: EventType.RECIPE,
  },
  {
    date: new Date(2024, 7, 30, 6, 0, 0),
    durationMinutes: 60,
    id: 2,
    name: "Workout Name Goes Here",
    selectedTraineeIds: [],
    type: EventType.WORKOUT,
  },
  {
    date: new Date(2024, 7, 30, 8, 0, 0),
    durationMinutes: 30,
    id: 3,
    name: "Workout Name Goes Here",
    selectedTraineeIds: [],
    type: EventType.WORKOUT,
  },
  {
    date: new Date(2024, 7, 31, 1, 0, 0),
    durationMinutes: 10,
    id: 4,
    name: "Recipe Name Goes Here",
    selectedTraineeIds: [],
    type: EventType.RECIPE,
  },
  {
    date: new Date(2024, 7, 31, 3, 0, 0),
    durationMinutes: 15,
    id: 5,
    name: "Recipe Name Goes Here",
    selectedTraineeIds: [],
    type: EventType.RECIPE,
  },
  {
    date: new Date(2024, 7, 31, 5, 0, 0),
    durationMinutes: 17,
    id: 6,
    name: "Workout Name Goes Here",
    selectedTraineeIds: [],
    type: EventType.WORKOUT,
  },
  {
    date: new Date(2024, 7, 31, 7, 0, 0),
    durationMinutes: 17,
    id: 7,
    name: "Workout Name Goes Here",
    selectedTraineeIds: [],
    type: EventType.WORKOUT,
  },
];
