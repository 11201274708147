import { useSetAtom } from "jotai";
import { useCallback } from "react";

import {
  confirmationModalActionAtom,
  confirmationModalSourceAtom,
  confirmationModalStatusAtom,
  type ConfirmationModalActionAtom,
} from "../atoms";

export default () => {
  const setAction = useSetAtom(confirmationModalActionAtom);
  const setSource = useSetAtom(confirmationModalSourceAtom);
  const setStatus = useSetAtom(confirmationModalStatusAtom);

  return useCallback(
    (action: ConfirmationModalActionAtom, source: string) => {
      setStatus("open");
      setAction(action);
      setSource(source);
    },
    [setAction, setSource, setStatus],
  );
};
