import { createClient } from "@supabase/supabase-js";
import { Dispatch, SetStateAction } from "react";

import { SUPABASE_ANON_KEY, SUPABASE_PROJECT_URL } from "./constants";
import {
  Availability,
  CommunicationMethodType,
  DbTable,
  Event_t,
  EventType,
  FeaturedCardType,
  FormattedId,
  IconType,
  InPersonAvailability,
  NewEvent_t,
} from "./types";

export const today = new Date();

export const supabase = createClient(SUPABASE_PROJECT_URL, SUPABASE_ANON_KEY);

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const noop = () => {};

export const wait = async (seconds: number) =>
  new Promise((_) => setTimeout(_, seconds * 1000));

export const login = async (email: string, password: string) => {
  const { data, error } = await supabase.auth.signInWithPassword({
    email,
    password,
  });
  console.log("data", data);
  console.log("error", error);
};

export const logout = async () => {
  const { error } = await supabase.auth.signOut();

  if (error) {
    console.error(error);
  }
};

// start: inclusive, end: exclusive
export const range = (end: number, start = 0) =>
  [...Array(end).keys()].filter((num) => num >= start);

export const secsToMins = (secs: number) => {
  return {
    min: secs > 59 ? (secs - (secs % 60)) / 60 : undefined,
    sec: secs % 60 > 0 ? secs % 60 : undefined,
  };
};

export const pluralize = (singularWord: string, pluralize: boolean) => {
  if (!pluralize) {
    return singularWord;
  }

  return (
    (singularWord.endsWith("y") && singularWord !== "day"
      ? singularWord.slice(0, singularWord.length - 1) + "ie"
      : singularWord) + "s"
  );
};

export const getDaysRemaining = (isoDate: string) => {
  return Math.ceil(
    (new Date(isoDate).getTime() - new Date().getTime()) / (1000 * 3600 * 24),
  );
};

export const areDaysSame = (date1: Date, date2: Date) =>
  date1.getFullYear() === date2.getFullYear() &&
  date1.getMonth() === date2.getMonth() &&
  date1.getDate() === date2.getDate();

export const classes = (
  styles: { [key: string]: string },
  classNames: (string | boolean | null | undefined)[],
) => ({
  className: classNames
    .filter((className): className is string => typeof className === "string")
    .map((className) => styles[className])
    .join(" "),
});

export const communicationMethodTypeToIconMap: Record<
  CommunicationMethodType,
  IconType
> = {
  [CommunicationMethodType.EMAIL]: IconType.MAIL,
  [CommunicationMethodType.PHONE_NUMBER]: IconType.CALL,
};

export const communicationMethodTypeToLabelMap: Record<
  CommunicationMethodType,
  string
> = {
  [CommunicationMethodType.EMAIL]: "Email",
  [CommunicationMethodType.PHONE_NUMBER]: "Phone number",
};

export const availabilityToLabelMap: Record<Availability, string> = {
  [Availability.IN_PERSON]: "In person",
  [Availability.ONLINE]: "Online",
  [Availability.BOTH]: "Both",
};

export const inPersonAvailabilityToLabelMap: Record<
  InPersonAvailability,
  string
> = {
  [InPersonAvailability.INCALL]: "Incall",
  [InPersonAvailability.OUTCALL]: "Outcall",
  [InPersonAvailability.BOTH]: "Both",
};

export const getPathFromId = ({ dbTable, rowId }: FormattedId) => {
  if (dbTable === DbTable.RECIPES) {
    return `/recipes/${rowId}`;
  }

  if (dbTable === DbTable.WORKOUTS) {
    return `/workouts/${rowId}`;
  }

  console.error(`Invalid ID type: ${dbTable}`);
  return "";
};

export const exclude = <T>(list: T[], itemToExclude: T) =>
  list.filter((listItem) => listItem !== itemToExclude);

export const append = <T>(list: T[], itemToAppend: T) => [
  ...list,
  itemToAppend,
];

export const replaceAtIndex = <T>(list: T[], index: number, newItem: T) => [
  ...list.slice(0, index),
  newItem,
  ...list.slice(index + 1),
];

export const removeAtIndex = <T>(list: T[], index: number) => [
  ...list.slice(0, index),
  ...list.slice(index + 1),
];

export const getInvalidFields = <T>(
  invalidFieldsMap: Record<string, boolean>,
): T[] =>
  Object.entries(invalidFieldsMap)
    .filter(([, isInvalid]) => isInvalid)
    .map(([key]) => key as T);

export const stripNonValues = <T>(list: (T | null | undefined)[]) =>
  list.filter((item): item is T => item !== undefined && item !== null);

export const createEvent = (
  setEvents: Dispatch<SetStateAction<Event_t[]>>,
  newEvent: NewEvent_t,
) =>
  setEvents((event) => {
    const nextId =
      event.map((event) => event.id).sort((id1, id2) => id1 - id2)[0] + 1;
    const nextEvent: Event_t = { ...newEvent, id: nextId };

    return [...event, nextEvent].sort(
      (e1, e2) => e1.date.getTime() - e2.date.getTime(),
    );
  });

export const deleteEvent = (
  setEvents: Dispatch<SetStateAction<Event_t[]>>,
  id: number,
) => setEvents((events) => events.filter((event) => event.id !== id));

export const getTestFeaturedEvents = (events: Event_t[]) => {
  const matchingRecipeEvent = events.find(
    (event) =>
      event.type === EventType.RECIPE && areDaysSame(event.date, today),
  );

  const matchingWorkoutEvent = events.find(
    (event) => event.type === EventType.WORKOUT,
  );

  return {
    todaysRecipeEvent: matchingRecipeEvent
      ? ({
          author: "(author)",
          colorStyle: "sky",
          id: { dbTable: DbTable.RECIPES, rowId: matchingRecipeEvent.id },
          tags: ["test tag 1", "test tag 2"],
          title: matchingRecipeEvent.name,
        } as FeaturedCardType)
      : undefined,
    todaysWorkoutEvent: matchingWorkoutEvent
      ? ({
          author: "(author)",
          colorStyle: "rose",
          id: { dbTable: DbTable.WORKOUTS, rowId: matchingWorkoutEvent.id },
          tags: ["test tag 1", "test tag 2"],
          title: matchingWorkoutEvent.name,
        } as FeaturedCardType)
      : undefined,
  };
};
