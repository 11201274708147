import {
  Combobox,
  ComboboxItem,
  ComboboxPopover,
  ComboboxProvider,
} from "@ariakit/react";
import { matchSorter } from "match-sorter";
import {
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";

import { append } from "../utils";

import styles from "../styles/components/Combobox.module.scss";

export interface ComboboxItem {
  id: string;
  image: string;
  name: string;
}

export default ({
  items,
  placeholder,
  setSelectedItems,
}: {
  items: ComboboxItem[];
  placeholder: string;
  setSelectedItems: Dispatch<SetStateAction<string[]>>;
}) => {
  const [inputValue, setInputValue] = useState<string>("");

  const addValueToList = useCallback(
    (id: string) => setSelectedItems((items) => append(items, id)),
    [setSelectedItems],
  );

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Backspace" && !inputValue) {
        setSelectedItems((selectedItems) => selectedItems.slice(0, -1));
      }
    },
    [inputValue, setSelectedItems],
  );

  const filteredItems = useMemo(
    () => matchSorter(items, inputValue, { keys: ["name"] }),
    [inputValue, items],
  );

  return (
    <ComboboxProvider
      resetValueOnHide
      setSelectedValue={(value) => addValueToList(value[0])}
      setValue={(value) => setInputValue(value)}
    >
      <Combobox
        {...{ placeholder }}
        className={styles["input"]}
        onKeyDown={handleKeyDown}
      />
      <ComboboxPopover className={styles["menu"]} gutter={4}>
        {filteredItems.length ? (
          filteredItems.map(({ id, image, name }) => (
            <ComboboxItem className={styles["item"]} key={id} value={id}>
              <img src={image} />
              <span>{name}</span>
            </ComboboxItem>
          ))
        ) : (
          <div className={styles["no-results"]}>No results found</div>
        )}
      </ComboboxPopover>
    </ComboboxProvider>
  );
};
