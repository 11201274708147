import { ComponentType, lazy, LazyExoticComponent, Suspense } from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { Page, PageLoader, Toaster, type PageType } from "./components";
import ErrorPage from "./pages/Error";

import "./index.css";
import "./styles/colors.scss";

const route = (
  path: string,
  component: LazyExoticComponent<ComponentType<PageType>>,
) => ({
  path,
  element: <Page element={component} />,
  errorElement: <ErrorPage />,
});

const Calendar = lazy(() => import("./pages/Calendar"));
const EditRecipe = lazy(() => import("./pages/EditRecipe"));
const EditWorkout = lazy(() => import("./pages/EditWorkout"));
const Login = lazy(() => import("./pages/Login"));
const Profile = lazy(() => import("./pages/Profile"));
const Recipe = lazy(() => import("./pages/Recipe"));
const Recipes = lazy(() => import("./pages/Recipes"));
const ReviewWorkout = lazy(() => import("./pages/ReviewWorkout"));
const Trainee = lazy(() => import("./pages/Trainee"));
const Workout = lazy(() => import("./pages/Workout"));
const Workouts = lazy(() => import("./pages/Workouts"));
const Home = lazy(() => import("./pages/Home"));
const Test = lazy(() => import("./pages/Test"));
const NotFound = lazy(() => import("./pages/NotFound"));
const Signup = lazy(() => import("./pages/Signup"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));

const router = createBrowserRouter([
  route("/", Home),
  route("/calendar", Calendar),
  route("/forgot-password", ForgotPassword),
  route("/login", Login),
  route("/profile", Profile),
  route("/recipes", Recipes),
  route("/recipes/new", EditRecipe),
  route("/recipes/:recipeId", Recipe),
  route("/recipes/:recipeId/edit", EditRecipe),
  route("/signup", Signup),
  route("/test", Test),
  route("/trainees/:traineeId", Trainee),
  route("/workouts", Workouts),
  route("/workouts/new", EditWorkout),
  route("/workouts/:workoutId", Workout),
  route("/workouts/:workoutId/edit", EditWorkout),
  route("/workouts/:workoutId/review", ReviewWorkout),
  route("*", NotFound),
]);

createRoot(document.getElementById("root") as HTMLElement).render(
  <Suspense fallback={<PageLoader />}>
    <RouterProvider {...{ router }} />
    <Toaster />
  </Suspense>,
);
