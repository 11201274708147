import { useAtomValue, useSetAtom } from "jotai";
import { useCallback } from "react";

import { currentOpenModalAtom, resetModalLocalStateAtom } from "../atoms";

export default () => {
  const setCurrentOpenModal = useSetAtom(currentOpenModalAtom);
  const resetModalLocalState = useAtomValue(resetModalLocalStateAtom);

  return useCallback(
    () =>
      setCurrentOpenModal((prev) => {
        if (prev) {
          resetModalLocalState[prev]?.();
        }

        return undefined;
      }),
    [resetModalLocalState, setCurrentOpenModal],
  );
};
