import { Button, ScrollingWrapper } from ".";
import { IconType, InvoiceStatus } from "../types";
import { classes, noop } from "../utils";

import styles from "../styles/components/InvoicesList.module.scss";

interface Invoice {
  dueDate: Date;
  id: string;
  issueDate: Date;
  link: string;
  status: InvoiceStatus;
  trainer: string;
}

const invoiceStatusToStyleMap: {
  [key in InvoiceStatus]: { string: string; className: string };
} = {
  [InvoiceStatus.OVERDUE]: { string: "Overdue", className: "overdue" },
  [InvoiceStatus.PAID]: { string: "Paid", className: "paid" },
  [InvoiceStatus.UNPAID]: { string: "Unpaid", className: "unpaid" },
  [InvoiceStatus.UPCOMING]: { string: "Upcoming", className: "upcoming" },
};

const Invoice = ({
  dueDate,
  id,
  issueDate,
  link,
  status,
  trainer,
}: Invoice) => {
  const { string, className } = invoiceStatusToStyleMap[status];
  const dueDateString = dueDate.toLocaleString("en-US", {
    timeZone: "UTC",
    month: "numeric",
    day: "numeric",
    year: "numeric",
  });
  const issueDateString = issueDate.toLocaleString("en-US", {
    timeZone: "UTC",
    month: "numeric",
    day: "numeric",
    year: "numeric",
  });
  return (
    <div className={styles["invoice"]}>
      <div className={styles["group"]}>
        <div>ID</div>
        {id}
      </div>
      <div className={styles["group"]}>
        <div>Status</div>
        <div {...classes(styles, ["status", className])}>{string}</div>
      </div>
      <div className={styles["group"]}>
        <div>Issue Date</div>
        {issueDateString}
      </div>
      <div className={styles["group"]}>
        <div>Due Date</div>
        {dueDateString}
      </div>
      <div className={styles["group"]}>
        <div>Trainer/Gym</div>
        {trainer}
      </div>
      <div className={styles["buttons"]}>
        <Button action={noop} label="View" size="medium" type="primary" />
        <Button
          action={() => console.log("download", link)}
          icon={{ type: IconType.DOWNLOAD }}
          size="medium"
          type="primary"
        />
      </div>
    </div>
  );
};

export default ({ invoices }: { invoices: Invoice[] }) => {
  return (
    <div {...classes(styles, ["component", !invoices.length && "empty"])}>
      Invoices
      {invoices.length ? (
        <ScrollingWrapper>
          {invoices.map((invoice, index) => (
            <Invoice {...invoice} key={index} />
          ))}
        </ScrollingWrapper>
      ) : (
        <div className={styles["empty-section"]}>No invoices</div>
      )}
    </div>
  );
};
